import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Container,
  Tab,
  Row,
  Col,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import moment from "moment";
import ExcellentExport from "excellentexport";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { MainContext } from "../../App";
import * as Sentry from "@sentry/react";

/** API Services */
import {
  GetStudentsList,
  GetStudentsListParent,
  GetProductConcepts,
} from "../../api/StudentsReport";
import { GetPreSignedLink } from "../../api/Crm";
import { getGradeLevels } from "../../api/StudentsReport";
/** Elements */
import { Table } from "../../components/table/Table";
import ContentTable from "../../components/table/ContentTable";
import ContentSolid from "../../components/global/ContentSolid";
import InputFilter from "../../components/table/InputFilter";
import SelectFilter from "../../components/table/SelectFilter";
import { LoadingTable } from "../../components/lazyLoading/LazyLoading";
import SchoolCycle from "../../components/global/form/SchoolCycle";
import {
  goToHref,
  dateCookie,
  domainCookie,
  getUrlParamValue,
  validateUserRoleIds,
} from "../../components/global/GlobalTools";
//Components
import ModalCreateStudent from "../../components/students/ModalCreateStudent";
import ModalImportStudent from "../../components/students/ModalImportStudent";
import ModalRelativeOrGuardians from "../../components/students/ModalInviteRelativeOrGuardians";
import ModalImportResult from "./modals/ModalImportResult";
import SuccessModal from "../../components/students/SuccessModal";
import ModalSendReportCards from "./modals/ModalSendReportCards";
import ModalSuccess from "../../components/global/modals/ModalSuccess";
import ModalActionStudents from "../../components/students/modals/ModalActionStudents";
import ModalConfirmationSuspendedStudents from "./modals/ModalConfirmationSuspendedStudents";
import ModalImportMedicalRecord from "../../components/students/ModalImportMedicalRecord";
import ModalDownloadCredentials from "./modals/ModalDownloadCredentials";
import ModalGenerateDiplomas from "../../components/students/modals/ModalGenerateDiplomas";
import AdditionalFieldsModal from "../../components/students/AdditionalFieldsModal";
import ModalTransferStudent from "./modals/ModalTransferStudent";

const customStyles = {
  badgeLocationStatus: {
    display: " inline-block",
    height: "8px",
    width: " 13px",
    marginRight: " 5px",
  },
  colorTable: {
    textDecoration: "none",
    color: "#575757",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
};

// Get the last cycle saved
let cycleStringify = localStorage.getItem("filterCycleStudents");

/**
 * Purpose: Save selected cycle
 */
const obtainFilterCycle = () => {
  let cycle = null;
  const prefix = process.env.REACT_APP_PREFIX;
  const currentOrganizationId = localStorage.getItem(
    `cmOrganizationID${prefix}`
  );
  // Parse last cycle saved
  const preselectedCycle = cycleStringify && JSON.parse(cycleStringify);
  // Evaluates if the last saved cycle matches the current organization id
  if (
    preselectedCycle &&
    preselectedCycle.organization_id == currentOrganizationId
  ) {
    cycle = preselectedCycle;
  }
  // Clean item in local storage
  localStorage.removeItem("filterCycleStudents");
  return cycle;
};

/**Get param bool to open import student modal */
const importModalId = getUrlParamValue("importStudent");
/**Get param to open create student modal */
const createModalId = getUrlParamValue("createStudent");
const StudentReportView = () => {
  const componentRef = useRef(); //Create the reference for printing
  const { permissions } = useContext(MainContext);
  const prefix = process.env.REACT_APP_PREFIX;
  const orgInfoName = `cmOrganizationInfo${prefix}`;
  const orgInfo = JSON.parse(localStorage.getItem(orgInfoName));
  const [t] = useTranslation([
    "reports",
    "global",
    "student",
    "address",
    "crm",
  ]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingView, setLoadingView] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [showEditColumns, setShowEditColumns] = useState(false);
  const [showCreateStudent, setShowCreateStudent] = useState(createModalId);
  const [showInviteRelativeOrGuardians, setShowInviteRelativeOrGuardians] =
    useState(false);
  const [showImportStudent, setShowImportStudent] = useState(importModalId);
  const [schoolCycle, setSchoolCycle] = useState([]);
  const [language] = useState(localStorage.getItem(`cmLanguage${prefix}`));
  const [showImportResult, setShowImportResult] = useState(false);
  const [importResult, setImportResult] = useState([]);
  const [openCreateCycleModal, setCreateCycleModal] = useState(false);
  const [rowIsSelect, setRowIsSelect] = useState(false);
  const [selectCycleId, setSelectCycleId] = useState("");
  const [selectGradeId, setSelectGradeId] = useState("");
  const [loadGrades, setLoadGrades] = useState(true);
  const [grades, setGrades] = useState([]);
  const [selectSchoolLevel, setSelectSchoolLevel] = useState(null);
  const [filters, setFilters] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [newStudent, setNewStudent] = useState({});
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [dropdownReportCardActions, setDropdownReportCardActions] =
    useState(false);
  const [showSendReportCards, setShowSendReportCards] = useState(false);
  const [showActionStudents, setShowActionStudents] = useState(false);
  const [filterCycle, setFilterCycle] = useState(obtainFilterCycle());
  const [filterCycleId, setFilterCycleId] = useState("");
  const [optionsConcepts, setOptionsConcepts] = useState([]);
  const [widthSize, setWidthSize] = useState(window.innerWidth);
  const [mobileSize] = useState(810);
  const [btnMarginR, setBtnMarginR] = useState({
    marginRight: window.innerWidth <= mobileSize ? "0px" : "10px",
  });
  const [count, setCount] = useState(0);
  const [showImportMedicalRecord, setShowImportMedicalRecord] = useState();
  const [showModalDownloadCredentials, setShowModalDownloadCredentials] =
    useState(false);
  const [showModalProcessingCredentials, setShowModalProcessingCredentials] =
    useState(false);
  const [showImportResultMedicalRecord, setShowImportResultMedicalRecord] =
    useState(false);
  const [downloadMessage, setDownloadMessage] = useState("");
  const [openDownloadFile, setOpenDonwloadFile] = useState(false);
  const [loadingDownloadFile, setLoadingDownloadFile] = useState(false);
  const [showModalGenerateDiplomas, setShowModalGenerateDiplomas] =
    useState(false);
  const [showModalProcessDiplomas, setShowModalProcessDiplomas] =
    useState(false);
  const [showAdditionalFieldsModal, setShowAdditionalFieldsModal] =
    useState(false);
  const [additonalFieldsSuccess, setAdditonalFieldsSuccess] = useState({
    showModal: false,
    data: {},
  });
  const [showRegisteredStudentModal, setShowRegisteredStudentModal] =
    useState(false);
  const [hasCycle, sethasCycle] = useState(false);
  const [showTransferStudentModal, setShowTransferStudentModal] =
    useState(false);

  // Get file_aws_path
  const fileAwsPath = getUrlParamValue("file_aws_path");
  // hide column for relatives and students
  const showColumStudentStatus = validateUserRoleIds([6, 8], null, "&&", true);
  // student status options
  let statusOptions = [
    {
      value: "active",
      label: t("crm:tableCrm.textActive"),
      id: t("crm:tableCrm.textActive"),
    },
    {
      value: "suspended",
      label: t("student:titlesTable.tileSuspended"),
      id: t("student:titlesTable.tileSuspended"),
    },
    {
      value: "unenrolled",
      label: t("student:titlesTable.titleUnenrolled"),
      id: t("student:titlesTable.titleUnenrolled"),
    },
  ];

  const [
    showModalConfirmationSuspeendedStudent,
    setShowModalConfirmationSuspeendedStudent,
  ] = useState(false);
  const [suspensionType, setSuspensionType] = useState({
    title: "",
    description: "",
  });
  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("studentsReport"),
  };
  const locationOptions = [
    {
      value: "inside",
      label: t("student:filterOptions.inTheInstitution"),
      id: t("student:filterOptions.inTheInstitution"),
    },
    {
      value: "outside",
      label: t("student:filterOptions.outsideTheInstitution"),
      id: t("student:filterOptions.outsideTheInstitution"),
    },
  ];
  const columns = React.useMemo(
    () => [
      {
        Header: t("headerTable.internalEnrollment"),
        accessor: "uniqueId",
        Filter: InputFilter,
        minWidth: dataTable.length && count != 0 ? 50 : 150,
        maxWidth: 200,
        filter: "inputFilter",
      },
      {
        Header: t("headerTable.name"),
        accessor: "name",
        Filter: InputFilter,
        minWidth: 50,
        maxWidth: 150,
      },
      {
        Header: t("student:filterOptions.location"),
        accessor: "location",
        Filter: (props) => (
          <SelectFilter defaultValues={locationOptions} {...props} />
        ),
        minWidth: 80,
        maxWidth: 100,
        Cell: ({ cell: { value } }) => {
          const values = JSON.parse(value);
          return (
            <React.Fragment>
              <div>
                {values.location != "" ? (
                  <Badge
                    pill
                    bg={
                      values.location ==
                      t("student:filterOptions.inTheInstitution")
                        ? "success"
                        : "secondary"
                    }
                    style={customStyles.badgeLocationStatus}
                  />
                ) : (
                  ""
                )}
                {values.location}
              </div>
              {/**Student location date */}
              <div>
                {values.location == t("student:filterOptions.inTheInstitution")
                  ? values.check_in_time != ""
                    ? moment(values.check_in_time).format("DD/MM/YYYY hh:mm A")
                    : ""
                  : values.check_out_time != ""
                  ? moment(values.check_out_time).format("DD/MM/YYYY hh:mm A")
                  : ""}
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: t("headerTable.schoolLevel"),
        accessor: "school_level",
        Filter: SelectFilter,
        minWidth: 80,
        maxWidth: 110,
      },
      {
        Header: t("headerTable.program"),
        accessor: "programName",
        Filter: SelectFilter,
        minWidth: 80,
        maxWidth: 110,
      },
      {
        Header: t("student:titlesTable.gradeLevel"),
        accessor: "grade",
        Filter: SelectFilter,
        minWidth: 50,
        maxWidth: 95,
      },
      {
        Header: t("headerTable.group"),
        accessor: "group",
        Filter: InputFilter,
        minWidth: 20,
        maxWidth: 80,
      },
      ...(showColumStudentStatus
        ? [
            {
              Header: t("crm:tableCrm.statusProspect"),
              accessor: "status",
              Filter: (props) => (
                <SelectFilter defaultValues={statusOptions} {...props} />
              ),
              minWidth: 50,
              width: 90,
              maxWidth: 100,
            },
          ]
        : []),
      {
        Header: t("headerTable.birthday"),
        accessor: "birthday",
        Filter: InputFilter,
        minWidth: 100,
        width: 100,
        maxWidth: 120,
      },
      {
        Header: t("headerTable.CURP"),
        accessor: "CURP",
        Filter: InputFilter,
        Cell: ({ cell: { value } }) => {
          return (
            <td style={{ wordBreak: "break-word", width: "100%" }}>
              {value ? value : ""}
            </td>
          );
        },
        minWidth: 90,
        width: 110,
        maxWidth: 130,
      },
      {
        Header: t("headerTable.email"),
        accessor: "email",
        Filter: InputFilter,
        Cell: ({ cell: { value } }) => {
          return (
            <td style={{ wordBreak: "break-word", width: "100%" }}>
              {value ? value : ""}
            </td>
          );
        },
        minWidth: 90,
        width: 200,
        maxWidth: 250,
      },
      {
        Header: t("headerTable.homePhone"),
        accessor: "homePhone",
        Filter: InputFilter,
        minWidth: dataTable.length && count != 0 ? 50 : 130,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.phone"),
        accessor: "phone",
        Filter: InputFilter,
        minWidth: dataTable.length && count != 0 ? 50 : 130,
        maxWidth: 150,
      },
      {
        Header: t("address:labels.address"),
        accessor: "address",
        Filter: InputFilter,
        minWidth: dataTable.length && count != 0 ? 50 : 80,
        maxWidth: 100,
      },
      {
        Header: t("address:labels.address2"),
        accessor: "secondAddress",
        Filter: InputFilter,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: t("address:labels.state"),
        accessor: "stateName",
        Filter: InputFilter,
        minWidth: dataTable.length && count != 0 ? 50 : 80,
        maxWidth: 150,
      },
      {
        Header: t("address:labels.city"),
        accessor: "cityName",
        Filter: InputFilter,
        minWidth: dataTable.length && count != 0 ? 50 : 120,
        maxWidth: 150,
      },
      {
        Header: t("address:labels.zipcode"),
        accessor: "zipCode",
        Filter: InputFilter,
        minWidth: 150,
        maxWidth: 150,
      },
    ],
    [loading]
  );
  const actionOptions = [
    { value: "list", label: "actionOptions.downloadPDF" },
    { value: "excel", label: "actionOptions.downloadExcel" },
  ];
  const reportCardOptions = [
    {
      value: "dowload",
      label: "student:reportCardDropdown.downloadOption",
      show: permissions.students_classes.access,
    },
    {
      value: "send",
      label: "student:reportCardDropdown.sendOption",
      show: permissions.students_classes.create,
    },
  ];
  const actionStudentOptions = [
    {
      value: "changeLevel",
      label: "student:modalActionStudents.titleChangeLevel",
      show: permissions.students_list.edit,
    },
    {
      value: "transferInstitution",
      label: "student:modalActionStudents.transferInstitution",
      show: permissions.students_list.edit && orgInfo?.organization_group_id,
      onClick: () => setShowTransferStudentModal(true),
    },
    {
      value: "suspend",
      label: "student:modalActionStudents.selectedSuspend",
      show: permissions.students_list.edit,
    },
    {
      value: "unenrolled",
      label: "student:modalActionStudents.selectedDropOut",
      show: permissions.students_list.edit,
    },
    {
      value: "delete",
      label: "global:buttons.delete",
      show: permissions.students_list.delete,
    },
  ];
  const [counterSelectedStudents, setCounterSelectedStudents] = useState(0);
  const [filterPeriods, setFilterperiods] = useState({
    programSchoolCycleId: false,
    schoolLevelCycleId: false,
  });
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [actionReportCard, setActionReportCard] = useState("");
  const [actionStudent, setActionStudent] = useState("");
  const [textsModalSuccessReportCards, setTextsModalSuccessReportCards] =
    useState({});
  const [textsModalActionStudents, setTextsModalActionStudents] = useState({});
  const [values, setValues] = useState(0);
  const [
    textModalSuccessImportMedicalRecord,
    setTextModalSuccessImportMedicalRecord,
  ] = useState({});

  window.onresize = () => {
    setWidthSize(window.innerWidth);
    setBtnMarginR(
      window.innerWidth <= mobileSize
        ? { marginRight: "0px" }
        : { marginRight: "10px" }
    );
    if (window.innerWidth <= mobileSize) {
      hideMobileViewColumns();
    }
  };

  const hideMobileViewColumns = () => {
    if (permissions.students_list.access) {
      setHiddenColumns([
        "location",
        "homePhone",
        "phone",
        "address",
        "secondAddress",
        "stateName",
        "cityName",
        "zipCode",
        "group",
        "birthday",
        "CURP",
        "email",
      ]);
    }
    if (permissions.organization_admin_students.access) {
      setHiddenColumns([
        "location",
        "homePhone",
        "phone",
        "address",
        "secondAddress",
        "stateName",
        "cityName",
        "zipCode",
        "group",
        "birthday",
        "CURP",
        "email",
      ]);
    }
  };
  /** Methods*/
  /**
   * Description: Effect to know if all students are selected
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
    // Assess that they belong to the same school level
    if (selectedRowIds.length) {
      let schoolLevels = [];
      let filterPeriod = {
        programSchoolCycleId: false,
        schoolLevelCycleId: false,
      };
      selectedRowIds.forEach((element) => {
        schoolLevels.push(element.original.school_level);
      });
      let level = schoolLevels[0];
      let firstStudent = selectedRowIds[0].original;
      if (firstStudent.programSchoolCycleId != null) {
        filterPeriod.programSchoolCycleId = firstStudent.programSchoolCycleId;
      } else if (firstStudent.organizationSchoolLevelCycleId != null) {
        filterPeriod.schoolLevelCycleId =
          firstStudent.organizationSchoolLevelCycleId;
      }
      setDropdownReportCardActions(
        schoolLevels.every((item) => item === level)
      );
      setFilterperiods(filterPeriod);
    }
  }, [selectedRowIds]);

  /**
   * Purpose: Gets the student data for the table
   * @param {object} [cycle=null]
   */
  const getStudentsList = (cycle = null) => {
    let schoolCycleId = cycle?.id || cycle;
    setLoading(true);
    setFilterCycleId(schoolCycleId);
    setFilterCycle(cycle);
    if (permissions.organization_admin_students.access) {
      GetStudentsList(schoolCycleId)
        .then((result) => {
          if (result && result.description) {
            Sentry.captureException(Error(JSON.stringify(result)));
            setLoading(false);
            setLoadingView(false);
            return;
          }
          let data =
            result && result.data && result.data.data ? result.data.data : [];
          formatTableData(data);
        })
        .finally(() => {
          sethasCycle(true);
        });
    } else {
      let parentId = localStorage.getItem(`cmUserID${prefix}`);
      GetStudentsListParent(parentId)
        .then((result) => {
          if (result.description) {
            Sentry.captureException(Error(JSON.stringify(result)));
            setLoading(false);
            setLoadingView(false);
            return;
          }
          let data = result.data.data;
          let students = [];
          data.map((details) => {
            let student = details;
            student.name =
              details.first_name +
              (details.middle_name ? " " + details.middle_name + " " : " ") +
              details.last_name;
            student.school_level = details.school_level
              ? details.school_level.name
              : "-";
            student.grade_level = details.grade_level
              ? details.grade_level.grade_level
              : null;
            student.grade_group = details.grade_group
              ? details.grade_group.name
              : null;
            student.grade = details.grade ? details.grade.grade_level_id : null;
            student.birth_date = details.birth_date_extended;
            student.status = details.status ? details.status : "N/A";
            students.push(student);
          });
          formatTableData(students);
        })
        .finally(() => {
          sethasCycle(true);
        });
    }
  };

  /** Format the data for the table */
  const formatTableData = (data) => {
    let list = [];
    for (let i = 0; i < data.length; i++) {
      let student = {};
      student.index = data[i].id;
      student.uniqueId = data[i].student_unique_id;
      student.name = data[i].name;
      student.school_level = data[i].school_level;
      student.grade = data[i].grade_level ? data[i].grade_level : "";
      student.group = data[i].grade_group ? data[i].grade_group : "";
      student.birthday = moment(data[i].birth_date, "YYYY-mm-DD");
      student.birthday = student.birthday.format("DD/mm/yyyy");
      student.CURP = data[i].curp;
      student.email = data[i].email;
      student.programSchoolCycleId = data[i].program_school_cycle_id;
      student.organizationSchoolLevelCycleId =
        data[i].organization_school_level_cycle_id;
      student.homePhone = data[i].home_phone;
      student.phone = data[i].phone;
      student.address = data[i].address;
      student.secondAddress = data[i].second_address;
      student.stateName = data[i].state_name;
      student.cityName = data[i].city_name;
      student.zipCode = data[i].zip_code;
      student.programName = data[i].program_name || "";
      student.status = data[i].status ? data[i].status : "N/A";
      student.programId = data[i].program_id || false;
      student.schoolLevelId = data[i].organization_school_level_id || false;
      student.gradeGroupGradeLevelId =
        data[i].grade_group_grade_level_id || false;
      student.notSelectable = validateUserRoleIds([6, 8])
        ? data[i].status == "Suspended" || data[i].status == "Suspendido"
        : false;
      student.linkToPage = `${process.env.REACT_APP_V1}/estudiante/${data[i].id}`;
      //Validate student location
      let location =
        data[i].location != null && data[i].location == "in_the_institution" //TODO: cache the location state parameter
          ? t("student:filterOptions.inTheInstitution")
          : data[i].location != null
          ? t("student:filterOptions.outsideTheInstitution")
          : "";
      let check_in_time =
        data[i].check_in_time != null ? data[i].check_in_time : "";
      let check_out_time =
        data[i].check_out_time != null ? data[i].check_out_time : "";
      let locationData = {
        location,
        check_in_time,
        check_out_time,
      };
      locationData = JSON.stringify(locationData);
      student.location = locationData;
      list.push(student);
    }
    setDataTable(list);
    setLoading(false);
    setLoadingView(false);
  };

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => dataTable, [loading]);

  /**
   * Purpose: Toggle for the "Create Student" modal
   */
  const toggleCreateStudent = () => {
    setShowCreateStudent(true);
  };

  /**
   * Purpose: Toggle for the "Invite Relatives or Guardians" modal
   */
  const toggleInviteRelativeOrGuardians = () => {
    setShowInviteRelativeOrGuardians(true);
  };

  /**
   * Purpose: Toggle for the "Import Student" modal
   */
  const toggleImportStudent = () => {
    setShowImportStudent(true);
  };

  /**
   * Purpose: Toggle to open and close "CreateCycleModal"
   */
  const toggleCreateCycle = () => {
    setCreateCycleModal(!openCreateCycleModal);
  };

  /**
   * Purpose : Toggle the state of the download credentials modal
   * @param {boolean} status
   */
  const toggleDownloadCredentialsModal = (status) => {
    setShowModalDownloadCredentials(status);
  };

  /**
   * Purpose: Toggle the state of the processing credentials modal
   * @param {boolean} status
   */
  const toggleProcessingCredentialsModal = (status) => {
    setShowModalProcessingCredentials(status);
    setShowModalDownloadCredentials(false);
    //Update the success text of the modal body
    const counter =
      counterSelectedStudents > 0 ? counterSelectedStudents : count;
    const downloadKey =
      counter === 1
        ? "requestDownloadCredential"
        : "requestDownloadCredentials";
    setDownloadMessage(
      t(`student:ModalDownloadCredentials.${downloadKey}`, {
        count: counter,
      })
    );
  };

  /**
   * Purpose: Redirects to APP V1 and stores the filters and hidden columns of the table
   * @param {Object} student //Contains student data
   * @param {Object} state //Contains the data of the table in general
   */
  const studentProfile = (student, state) => {
    let filters = state.filters; //Get the filters of the table
    let hiddenColumns = state.hiddenColumns; //Get the hidden columns of the table
    localStorage.setItem("filtersStudents", JSON.stringify(filters)); //Save the filters in the cookie
    localStorage.setItem("filterCycleStudents", JSON.stringify(filterCycle)); //save the current cycle in the local storage
    localStorage.setItem(
      "hiddenColumnsStudents",
      JSON.stringify(hiddenColumns)
    ); //Save the hidden columns in the cookie
    goToHref(`/estudiante/${student.index}`);
  };

  /**Initial loading */
  useEffect(() => {
    Sentry.setTag("section", "Students Report");
    document.title = language == "spanish" ? "Estudiantes" : "Students";
    // Update the cookie "cmSourceView"
    Cookies.set(`cmSourceView${prefix}`, "students", {
      expires: dateCookie(),
      domain: domainCookie(),
    });
    // Hide for first listing call
    if (window.innerWidth <= mobileSize) {
      hideMobileViewColumns();
    }
    // Retrieves the data of the filters and hidden columns of the cookies
    setPreviusFilters();
    // Open download file modal
    getFileAwsPath();
    // If there is a cycle filter, get the list of students by the cycle filter
    if (filterCycle) {
      getStudentsList(filterCycle);
    }
    // Get available students for the user who does not have access to the school year selector.
    if (!permissions.config_cycle.access) {
      getStudentsList();
    }
  }, []);

  /**
   * Function to retrieve data from filters and hidden cookie columns
   */
  const setPreviusFilters = () => {
    let dataFilter = JSON.parse(localStorage.getItem("filtersStudents"));
    let dataHiddenColumns = JSON.parse(
      localStorage.getItem("hiddenColumnsStudents")
    );
    if (dataFilter) {
      //Update the filters with what's in the cookie
      setFilters(dataFilter);
      localStorage.removeItem("filtersStudents");
    }
    if (dataHiddenColumns) {
      //Update the hidden columns with what's in the cookie
      setHiddenColumns(dataHiddenColumns);
      localStorage.removeItem("hiddenColumnsStudents");
    }
  };

  /**
   * Function to know if a file needs to be downloaded by aws
   */
  const getFileAwsPath = () => {
    if (
      fileAwsPath &&
      typeof fileAwsPath == "string" &&
      fileAwsPath.length > 0
    ) {
      if (getUrlParamValue("type") === "diplomas") {
        getPreSignedLink("diplomas_details");
      } else {
        setOpenDonwloadFile(true);
      }
    }
  };

  /**
   * Description: Function to get file from aws
   * @param {string} [section="students"]
   */
  const getPreSignedLink = (section = "students") => {
    setLoadingDownloadFile(true);
    const request = {
      section,
      aws_path: fileAwsPath,
    };
    GetPreSignedLink(request)
      .then((result) => {
        if (result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
        } else {
          const data = result.data;
          window.open(data);
        }
      })
      .finally(() => {
        setLoadingDownloadFile(false);
        setOpenDonwloadFile(false);
      });
  };

  //Export to Excel
  const downloadFile = (exportOptions, exportData) => {
    ExcellentExport.convert(exportOptions, exportData);
  };

  /**
   * row select
   */
  const rowSelect = (value) => {
    setRowIsSelect(value);
  };

  /**
   * Get grade levels
   * @param {Id} schoolLeveId
   */
  const getGrades = (schoolLeveId) => {
    setGrades([]);
    if (!schoolLeveId) {
      return;
    }
    setLoadGrades(true);
    getGradeLevels(schoolLeveId).then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setLoadGrades(false);
        return;
      }
      let grades = [
        {
          id: "",
          value: "",
          label: t("modalCreateStudent:labels.selectGrade"),
        },
      ];
      let data = result.data.data;
      if (data && data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          grades.push({
            id: data[index].id,
            value: data[index].id,
            label: data[index].grade_level,
          });
        }
        setGrades(grades);
      }
      setLoadGrades(false);
    });
  };

  /**
   * Description: Function to get all product concepts
   */
  const getProductConcepts = () => {
    setOptionsConcepts([]);
    const request = {
      school_cycle_id: filterCycleId,
    };
    GetProductConcepts(request)
      .then((res) => {
        if (res.description) {
          Sentry.captureException(Error(JSON.stringify(res)));
          return;
        }
        const data = res.data.data;
        const optionsConcepts = [];
        for (const concept of data) {
          optionsConcepts.push({
            value: concept.id,
            label: "<span class='text-ellipsis'>" + concept.name + "</span>",
            isDisabled: false,
            text: concept.name,
            products: concept.products,
          });
        }
        setOptionsConcepts(optionsConcepts);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  /**
   * Action selector for reportCards
   * @param {string} action
   */
  const reportCardActions = (action) => {
    setShowSendReportCards(true);
    setActionReportCard(action);
  };

  /**
   * Action selector for ModalActionStudents
   * @param {string} action
   */
  const studentsActions = (action) => {
    //Object with texts for ModalActionStudents
    const info = {
      addCharges: {
        title: t("student:modalActionStudents.addCharges"),
        message: "",
        btnAction: t("student:modalActionStudents.addCharges"),
        btnActionLoading: "",
      },
      changeLevel: {
        title: t("student:modalActionStudents.titleChangeLevel"),
        message: "",
        btnAction: t("student:modalActionStudents.titleChangeLevel"),
        btnActionLoading: t("global:buttons.changing"),
      },
      suspend: {
        title: t("student:modalActionStudents.titleSuspendStudent"),
        message: t("student:modalActionStudents.textAdministrativeRestriction"),
        btnAction: t("global:buttons.accept"),
        btnActionLoading: t("global:buttons.inactivating"),
      },
      unenrolled: {
        title: t("student:modalActionStudents.titleStudentDisenrollment"),
        message: t("student:modalActionStudents.textModalSuspendStudent"),
        btnAction: t("global:buttons.accept"),
        btnActionLoading: t("global:buttons.accept"),
      },
      delete: {
        title: t("student:modalActionStudents.titleDelete"),
        message: t("student:modalActionStudents.descriptionDelete"),
        btnAction: t("global:buttons.delete"),
        btnActionLoading: t("global:buttons.deleting"),
      },
    };
    const confirmInfo = {
      suspend: {
        title: t("student:modalActionStudents.titlStudentSuspended"),
        description: t(
          "student:modalActionStudents.textModalConfirmationSuspend"
        ),
      },
      unenrolled: {
        title: t("student:modalActionStudents.titleModalStudentTerminated"),
        description: t("student:modalActionStudents.textModalSuccessfully"),
      },
    };
    setSuspensionType(confirmInfo[action]);
    //Set texts for each element
    setTextsModalActionStudents({
      title: info[action].title,
      message: info[action].message,
      btnAction: info[action].btnAction,
      btnActionLoading: info[action].btnActionLoading,
    });
    //Call functions according action
    action === "addCharges" ? getProductConcepts() : null;
    //Save the action
    setActionStudent(action);
    //Show modal
    setShowActionStudents(true);
  };

  /**
   * Modal toggle- action completed
   */
  const reportCardsSuccessfullySent = () => {
    let title =
      actionReportCard === "send"
        ? t("student:modalSendReportCards.reportCardsSent")
        : t("student:modalSendReportCards.sentForDownload");
    let message =
      actionReportCard === "send"
        ? t("student:modalSendReportCards.mailingProcessInitiated")
        : t("student:modalSendReportCards.sentForDownloadMessage");
    setTextsModalSuccessReportCards({
      title,
      message,
    });
    setShowModalSuccess(!showModalSuccess);
    if (!showModalSuccess) {
      let selectAll = {
        switch: !selectAllRows.switch,
        value: false,
      };
      setSelectAllRows(selectAll);
    }
  };
  /**
   * Modal toggle - Success Medical Record
   */
  const modalSuccessImportMedicalRecord = () => {
    let title = t("student:ModalResultImportMedicalRecord.processingTitle");
    let message = t(
      "student:ModalResultImportMedicalRecord.descripcionMessage"
    );
    setTextModalSuccessImportMedicalRecord({
      title,
      message,
    });
    setShowImportResultMedicalRecord(!showImportResultMedicalRecord);
  };

  /**
   * Tooltip interface for report card actions
   * @param {object} props
   * @returns element
   */
  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t("student:reportCardDropdown.tootipMessage")}
      </Tooltip>
    );
  };

  /**
   * Purpose: Toggle for the "Import Medical Record" modal
   */
  const toggleImportMedicalRecord = () => {
    setShowImportMedicalRecord(true);
  };

  /**
   * Purpose: Function to select or deselect all records
   */
  const selectAllRecords = () => {
    let selectAll = {
      switch: !selectAllRows.switch,
      value: !selectAllRows.value,
    };
    setSelectAllRows(selectAll);
  };

  /**
   * Description: Function to show GenerateDiplomasModal
   */
  const toggleDownloadDiplomas = () => {
    setShowModalGenerateDiplomas((prev) => !prev);
  };

  const toggleModalProcessDiplomas = () => {
    setShowModalProcessDiplomas((prev) => !prev);
  };

  /**
   * Description: Function to stop loading when the organization has no cycles
   */
  const stopLoading = () => {
    setLoading(false);
    setLoadingView(false);
    sethasCycle(false);
  };

  return permissions.students_list.access ? (
    <>
      <Container fluid>
        <Row>
          <Col md={12} lg={2}>
            <h2>{t("reports.students")}</h2>
          </Col>
          {permissions.config_cycle.access ? (
            <Col
              md={12}
              lg={3}
              xs={12}
              className={widthSize <= mobileSize ? "mt-2" : ""}
            >
              {/** Filter Select School Cycle */}
              <SchoolCycle
                useIn="filters"
                handleOnchange={getStudentsList}
                selectedByDefault={true}
                getDefaultValues={!filterCycle && getStudentsList}
                value={filterCycle}
                notCycles={stopLoading}
              />
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className={widthSize <= mobileSize ? "mt-2" : "mt-5"}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {permissions.organization_admin_students.access ? (
                  <Row style={{ marginBottom: "20px" }}>
                    <Col md={12} lg={6} xs={12} style={{ width: "45%" }}>
                      {/** Select all students button */}
                      <div
                        className={
                          widthSize <= mobileSize ? "d-grid gap-2" : ""
                        }
                      >
                        {/* Actions available to selected students    */}
                        {permissions.students_list.edit ||
                        permissions.students_list.delete ? (
                          <Dropdown
                            style={
                              widthSize <= mobileSize
                                ? { marginRight: "0px" }
                                : {
                                    marginRight: "10px",
                                    display: "inline-block",
                                  }
                            }
                            className={
                              widthSize <= mobileSize ? "d-grid gap-2" : ""
                            }
                          >
                            <Dropdown.Toggle
                              id="dropdownReportCards"
                              variant="outline-secondary"
                              disabled={!counterSelectedStudents}
                            >
                              {t("global:buttons.action")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item disabled>
                                {t("student:reportCardDropdown.settings")}
                              </Dropdown.Item>
                              {filterCycleId &&
                              permissions.students_charges.create ? (
                                <Dropdown.Item
                                  eventKey={"addCharges"}
                                  onClick={() => studentsActions("addCharges")}
                                >
                                  {t("student:modalActionStudents.addCharges")}
                                </Dropdown.Item>
                              ) : null}
                              {actionStudentOptions.map((option, index) => {
                                return (
                                  option.show && (
                                    <Dropdown.Item
                                      key={index}
                                      eventKey={option.value}
                                      className={
                                        option.value === "delete"
                                          ? "modal-title"
                                          : ""
                                      }
                                      onClick={() =>
                                        option.onClick
                                          ? option.onClick()
                                          : studentsActions(option.value)
                                      }
                                    >
                                      {t(option.label)}
                                    </Dropdown.Item>
                                  )
                                );
                              })}
                              <Dropdown.Divider />
                              <Dropdown.Item disabled>
                                {t("student:reportCardDropdown.title")}
                              </Dropdown.Item>
                              {reportCardOptions.map((option, index) => (
                                <OverlayTrigger
                                  key={index}
                                  placement="right"
                                  overlay={
                                    !dropdownReportCardActions ? (
                                      renderTooltip
                                    ) : (
                                      <></>
                                    )
                                  }
                                >
                                  <span>
                                    <Dropdown.Item
                                      eventKey={option.value}
                                      onClick={() =>
                                        reportCardActions(option.value)
                                      }
                                      disabled={!dropdownReportCardActions}
                                    >
                                      {t(option.label)}
                                    </Dropdown.Item>
                                  </span>
                                </OverlayTrigger>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : null}

                        {/* Total counter of records and selected records in students table */}
                        {selectedRowIds.length ? (
                          selectedRowIds.length == 1 ? (
                            <p
                              className="d-inline"
                              style={customStyles.textDangerBold}
                            >
                              {t("table:textTableViews.selectedRecord", {
                                countSelected: selectedRowIds.length,
                              })}
                            </p>
                          ) : (
                            <p
                              className="d-inline"
                              style={customStyles.textDangerBold}
                            >
                              {t("table:textTableViews.selectedRecords", {
                                countSelected: selectedRowIds.length,
                              })}
                            </p>
                          )
                        ) : (
                          <p
                            className="d-inline"
                            style={customStyles.textGrayBold}
                          >
                            {t("table:textTableViews.totalRecords", {
                              count: count,
                            })}
                          </p>
                        )}
                      </div>
                    </Col>

                    {/** Section for Print and Export buttons */}
                    <Col
                      md={12}
                      lg={6}
                      xs={12}
                      style={{ position: "relative", width: "55%" }}
                    >
                      <div
                        style={
                          widthSize <= mobileSize
                            ? { paddingTop: "10px" }
                            : { paddingTop: "5px", float: "right" }
                        }
                        className={
                          widthSize <= mobileSize ? "d-grid gap-2" : ""
                        }
                      >
                        {/* Control escolar  */}
                        {(permissions.students_list.create ||
                          permissions.students_medical_info.create ||
                          permissions.students_list.access) && (
                          <Dropdown
                            style={
                              widthSize <= mobileSize
                                ? { marginRight: "0px" }
                                : {
                                    marginRight: "10px",
                                    display: "inline-block",
                                  }
                            }
                            className={
                              widthSize <= mobileSize ? "d-grid gap-2" : ""
                            }
                          >
                            <Dropdown.Toggle
                              id="dropdownButtonImport"
                              variant="outline-secondary"
                              disabled={!hasCycle}
                            >
                              <i className="bi bi-gear"></i>
                              {t("student:schoolControlDropdown.schoolControl")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {/* Import students */}
                              {permissions.students_list.create && (
                                <Dropdown.Item
                                  onClick={() => toggleImportStudent(true)}
                                  disabled={!hasCycle}
                                >
                                  {t("student:modalImport.importStudents")}
                                </Dropdown.Item>
                              )}
                              {/* Import medical record */}
                              {permissions.students_medical_info.create && (
                                <Dropdown.Item
                                  onClick={() =>
                                    toggleImportMedicalRecord(true)
                                  }
                                >
                                  {t("student:modalImport.importMedicalRecord")}
                                </Dropdown.Item>
                              )}
                              {/* invite parents or guardians */}
                              {permissions.students_list.access && (
                                <Dropdown.Item
                                  id="inviteRelativeOrGuardians"
                                  onClick={() =>
                                    toggleInviteRelativeOrGuardians(true)
                                  }
                                >
                                  {t(
                                    "student:schoolControlDropdown.inviteRelativeOrGuardians"
                                  )}
                                </Dropdown.Item>
                              )}
                              {/* Additional Fields */}
                              <Dropdown.Item
                                id="additionalFields"
                                onClick={() =>
                                  setShowAdditionalFieldsModal(true)
                                }
                              >
                                {t(
                                  "student:schoolControlDropdown.additionalFields"
                                )}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        {/** Download credentials */}
                        {permissions.student_generate_credentials.access && (
                          <Button
                            id="downloadStudentCredentialsBtn"
                            variant="outline-secondary"
                            style={
                              widthSize <= mobileSize
                                ? { marginRight: "0px" }
                                : {
                                    marginRight: "10px",
                                    display: "inline-block",
                                  }
                            }
                            onClick={() => {
                              toggleDownloadCredentialsModal(true);
                            }}
                            disabled={!count}
                          >
                            <i className="bi bi-download"></i>
                            {t("global:buttons.Credentials")}
                          </Button>
                        )}

                        {/** Download report */}
                        <Dropdown
                          style={
                            widthSize <= mobileSize
                              ? { marginRight: "0px" }
                              : { marginRight: "10px", display: "inline-block" }
                          }
                          className={
                            widthSize <= mobileSize ? "d-grid gap-2" : ""
                          }
                        >
                          <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            variant="outline-secondary"
                            disabled={printExport}
                          >
                            <i className="bi bi-download"></i>
                            {rowIsSelect
                              ? t("global:buttons.selectTable")
                              : t("global:buttons.download")}
                          </Dropdown.Toggle>
                          <Dropdown.Menu disabled={printExport}>
                            {actionOptions.map((action) => {
                              return action.value == "list" ? (
                                <ReactToPrint
                                  trigger={() => (
                                    <Dropdown.Item>
                                      {t(action.label)}
                                    </Dropdown.Item>
                                  )}
                                  content={() => componentRef.current}
                                />
                              ) : (
                                <ReactToPrint
                                  trigger={() => (
                                    <Dropdown.Item>
                                      {t(action.label)}
                                    </Dropdown.Item>
                                  )}
                                  content={() =>
                                    downloadFile(exportOptions, exportData)
                                  }
                                />
                              );
                            })}
                            {permissions.students_certificates?.create && (
                              <Dropdown.Item
                                id="dropdownDiplomas"
                                disabled={loading || !count}
                                onClick={toggleDownloadDiplomas}
                              >
                                {t(
                                  "student:ModalGenerateDiplomas.dropdownOption"
                                )}
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                        {permissions.students_list.create && (
                          <a
                            href="?createStudent=true"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            style={{ cursor: !hasCycle && "default" }}
                          >
                            <Button
                              id="createNewStudentBtn"
                              style={btnMarginR}
                              variant="primary"
                              onClick={() => toggleCreateStudent(true)}
                              size="lg"
                              disabled={!hasCycle}
                            >
                              <i
                                className="bi bi-person-plus-fill"
                                style={{ fontSize: "15px" }}
                              ></i>
                              {t("global:buttons.createStudent")}
                            </Button>
                          </a>
                        )}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {loadingView ? (
                  <ContentSolid style={{ marginTop: "20px" }}>
                    <LoadingTable />
                  </ContentSolid>
                ) : (
                  <>
                    {/** Table */}
                    {/*The startColumFilter and lastColumFilter props indicates in which column the box with the magnifying glass should appear in the filters*/}
                    <ContentTable startColumFilter={1} lastColumFilter={8}>
                      <Table
                        id="studentList"
                        ref={componentRef}
                        columns={columns}
                        data={data}
                        loading={loading}
                        rowSelect={true}
                        summation={false} //Indicates if there will be a row of sums
                        filters={filters} //Contains the table filters
                        hiddenColumns={hiddenColumns} //Contains the hidden columns of the table
                        setExportData={setExportData}
                        setPrintExport={setPrintExport}
                        showEditColumns={showEditColumns} //Flag for the modal "Edit List Columns"
                        setShowEditColumns={setShowEditColumns} //Toggle function of the "Edit List Columns" modal
                        rowIsSelect={rowSelect}
                        rowOnclick={studentProfile}
                        selectAllRows={selectAllRows}
                        setIsAllRowsSelected={setIsAllRowsSelected}
                        setSelectedRowIds={setSelectedRowIds}
                        setValues={setValues} //function to set total filtered data
                        showColumnGear={
                          permissions.organization_admin_students.access
                        }
                        setCounterSelectedStudents={setCounterSelectedStudents} //set the counter of the selected students from the table
                        limitRowsShown={50}
                        isLinked={true}
                        setRowsDisplayed={setCount}
                        enableCheckToSelectAllRecords={true}
                        selectAllRecords={selectAllRecords}
                      />
                    </ContentTable>
                  </>
                )}
                {showInviteRelativeOrGuardians && (
                  <ModalRelativeOrGuardians
                    showInviteRelativeOrGuardians={
                      showInviteRelativeOrGuardians
                    }
                    setShowInviteRelativeOrGuardians={
                      setShowInviteRelativeOrGuardians
                    }
                    dataTable={dataTable}
                  />
                )}
                {showImportStudent && (
                  <ModalImportStudent
                    showImportStudent={showImportStudent}
                    setShowImportStudent={setShowImportStudent}
                    schoolCycle={schoolCycle}
                    setShowImportResult={setShowImportResult}
                    setImportResult={setImportResult}
                    handleClose={toggleCreateCycle}
                  />
                )}
                {showCreateStudent && (
                  <ModalCreateStudent
                    showCreateStudent={showCreateStudent}
                    setShowCreateStudent={setShowCreateStudent}
                    schoolCycle={schoolCycle}
                    handleClose={toggleCreateCycle}
                    setSelectCycleId={setSelectCycleId}
                    selectCycleId={selectCycleId}
                    setSelectGradeId={setSelectGradeId}
                    selectGradeId={selectGradeId}
                    getGrades={getGrades}
                    loadGrades={loadGrades}
                    grades={grades}
                    setSelectSchoolLevel={setSelectSchoolLevel}
                    selectSchoolLevel={selectSchoolLevel}
                    setSchoolCycle={setSchoolCycle}
                    setOpenSuccessModal={setOpenSuccessModal}
                    setNewStudent={setNewStudent}
                    reloadStudentList={getStudentsList}
                    permissions={permissions}
                  />
                )}
                {showImportResult && (
                  <ModalImportResult
                    showImportResult={showImportResult}
                    importResult={importResult}
                    setShowImportResult={setShowImportResult}
                    isByCronjob={true}
                    typeUsers={t("reports.students")}
                  />
                )}
                {showImportMedicalRecord && (
                  <ModalImportMedicalRecord
                    showImportMedicalRecord={showImportMedicalRecord}
                    setShowImportMedicalRecord={setShowImportMedicalRecord}
                    setShowImportResultMedicalRecord={
                      setShowImportResultMedicalRecord
                    }
                    modalSuccessImportMedicalRecord={
                      modalSuccessImportMedicalRecord
                    }
                    schoolCycleId={filterCycleId}
                    selectedRowIds={selectedRowIds}
                  />
                )}
                {showModalDownloadCredentials && (
                  <ModalDownloadCredentials
                    showModalDownloadCredentials={showModalDownloadCredentials}
                    setShowModalDownloadCredentials={
                      toggleDownloadCredentialsModal
                    }
                    toggleModalsCredentials={toggleProcessingCredentialsModal}
                    count={
                      counterSelectedStudents > 0
                        ? counterSelectedStudents
                        : count
                    }
                    selectedRowIds={selectedRowIds}
                    schoolCycleId={filterCycleId}
                  />
                )}
                {showModalProcessingCredentials && (
                  <ModalSuccess
                    showModalSuccess={showModalProcessingCredentials}
                    size={"large"}
                    title={t("global:buttons.processing")}
                    message={downloadMessage}
                    fnAcceptButton={() =>
                      toggleProcessingCredentialsModal(false)
                    }
                  />
                )}
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
        {/**SuccessModal*/}
        <SuccessModal
          open={openSuccessModal}
          handleClose={setOpenSuccessModal}
          setShowCreateStudent={toggleCreateStudent}
          newStudentData={newStudent}
        />
        {showImportResultMedicalRecord && (
          <ModalSuccess
            showModalSuccess={showImportResultMedicalRecord}
            fnAcceptButton={modalSuccessImportMedicalRecord}
            title={textModalSuccessImportMedicalRecord.title}
            message={textModalSuccessImportMedicalRecord.message}
          />
        )}
      </Container>
      {showSendReportCards ? (
        <ModalSendReportCards
          reportCardsSuccessfullySent={reportCardsSuccessfullySent}
          setShowSendReportCards={setShowSendReportCards}
          counterSelectedStudents={counterSelectedStudents}
          showSendReportCards={showSendReportCards}
          actionReportCard={actionReportCard}
          selectedRowIds={selectedRowIds}
          filterPeriods={filterPeriods}
        />
      ) : null}
      {showModalSuccess ? (
        <ModalSuccess
          showModalSuccess={showModalSuccess}
          title={textsModalSuccessReportCards.title}
          message={textsModalSuccessReportCards.message}
          fnAcceptButton={reportCardsSuccessfullySent}
          size={"lg"}
        />
      ) : null}
      {showActionStudents ? (
        <ModalActionStudents
          size={"md"}
          showActionStudents={showActionStudents}
          setShowActionStudents={setShowActionStudents}
          selectedRowIds={selectedRowIds}
          actionStudent={actionStudent}
          title={textsModalActionStudents.title}
          message={textsModalActionStudents.message}
          btnActionTxt={textsModalActionStudents.btnAction}
          btnActionLoading={textsModalActionStudents.btnActionLoading}
          getStudentsList={getStudentsList}
          setSelectSchoolLevel={setSelectSchoolLevel}
          setSelectGradeId={setSelectGradeId}
          setSelectCycleId={setSelectCycleId}
          selectSchoolLevel={selectSchoolLevel}
          selectGradeId={selectGradeId}
          optionsConcepts={optionsConcepts}
          setOptionsConcepts={setOptionsConcepts}
          cycleId={filterCycleId}
          isAllRowsSelected={isAllRowsSelected}
          students={values}
          language={language}
          setShowModalConfirmationSuspeendedStudent={
            setShowModalConfirmationSuspeendedStudent
          }
          setShowRegisteredStudentModal={setShowRegisteredStudentModal}
        />
      ) : null}
      {showModalConfirmationSuspeendedStudent && (
        <ModalConfirmationSuspendedStudents
          showModalConfirmationSuspeendedStudent={
            showModalConfirmationSuspeendedStudent
          }
          setShowModalConfirmationSuspeendedStudent={
            setShowModalConfirmationSuspeendedStudent
          }
          selectedRowIds={selectedRowIds}
          suspensionType={suspensionType}
          getStudentsList={() => {
            // Get current School Cycle
            const cycle = JSON.parse(
              localStorage.getItem("filterCycleStudents")
            );
            getStudentsList(cycle);
          }}
        />
      )}
      {/**Download ZIP */}
      {openDownloadFile && (
        <ModalSuccess
          showModalSuccess={openDownloadFile}
          title={t("student:ModalDownloadCredentials.downloadFileTitle")}
          message={t(
            "student:ModalDownloadCredentials.downloadFileDescription"
          )}
          fnAcceptButton={() => getPreSignedLink()}
          size={"md"}
          txtBtnAccept={t("global:buttons.download")}
          fnCancelButton={() => setOpenDonwloadFile(false)}
          loading={loadingDownloadFile}
        />
      )}
      {showModalGenerateDiplomas && (
        <ModalGenerateDiplomas
          showModalGenerateDiplomas={showModalGenerateDiplomas}
          toggleDownloadDiplomas={toggleDownloadDiplomas}
          toggleModalProcessDiplomas={toggleModalProcessDiplomas}
          studentIds={selectedRowIds.length ? selectedRowIds : values}
        />
      )}
      {showModalProcessDiplomas && (
        <ModalSuccess
          showModalSuccess={showModalProcessDiplomas}
          title={t("global:buttons.processing")}
          message={t(
            selectedRowIds.length === 1 || values.length === 1
              ? "student:ModalGenerateDiplomas.successGenerateDiploma"
              : "student:ModalGenerateDiplomas.successGenerateDiplomas",
            {
              count: selectedRowIds.length || values.length,
            }
          )}
          fnAcceptButton={toggleModalProcessDiplomas}
        />
      )}

      {showAdditionalFieldsModal && (
        <AdditionalFieldsModal
          showAdditionalFieldsModal={showAdditionalFieldsModal}
          setShowAdditionalFieldsModal={setShowAdditionalFieldsModal}
          setAdditonalFieldsSuccess={setAdditonalFieldsSuccess}
        />
      )}
      {additonalFieldsSuccess.showModal && (
        <ModalSuccess
          showModalSuccess={additonalFieldsSuccess.showModal}
          title={additonalFieldsSuccess.data.title}
          message={additonalFieldsSuccess.data.message}
          fnAcceptButton={setAdditonalFieldsSuccess}
        />
      )}
      {showRegisteredStudentModal && (
        <ModalSuccess
          showModalSuccess={showRegisteredStudentModal}
          title={t("student:ModalRegisteredStudent.registeredStudentTitle")}
          message={t(
            "student:ModalRegisteredStudent.registeredStudentDescription"
          )}
          fnAcceptButton={() => setShowRegisteredStudentModal(false)}
        />
      )}
      {/**Add modal */}
      {showTransferStudentModal && (
        <ModalTransferStudent
          showModal={showTransferStudentModal}
          onHide={() => setShowTransferStudentModal(false)}
          organizationGroup={orgInfo.organization_group_id}
          selectedRowIds={selectedRowIds}
        />
      )}
    </>
  ) : (
    goToHref("/landing_page")
  );
};

export default StudentReportView;
StudentReportView.propTypes = {
  cell: PropTypes.object,
};
