import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  OverlayTrigger,
  Row,
  Tab,
  Table,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateView from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import {
  CreateMultipleAttendance,
  CreateStudentComment,
  SaveStudentsScore,
} from "../../api/class";
import {
  GetStudentsAttendanceClass,
  GetStudentsClassV2_1,
  GetTeacherClassesList,
} from "../../api/Dashboard";
import TextLoading from "../global/TextLoading";
import PropTypes from "prop-types";
import {
  getUrlParamValue,
  removeUrlParams,
  validateUserRoleIds,
} from "../global/GlobalTools";
import { useHistory } from "react-router-dom";
import SeeAllModule from "./admin/SeeAllModule";

const prefix = process.env.REACT_APP_PREFIX;
const customStyles = {
  checkBox: {
    width: "16px",
    height: "16px",
  },
  overflowScrollY: {
    overflowY: "scroll",
    overflowX: "hidden",
  },
  biGearIcon: {
    display: "flex",
    alignItems: "center",
    float: "right",
    cursor: "pointer",
    padding: "4px",
  },
  biFullScreenIcon: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    padding: "4px",
    color: "#ea2c54",
  },
  dropDownCriteria: {
    position: "absolute",
    top: "140px",
    right: "60px",
  },
};
const lazyLoadingTable = () => {
  return (
    <div>
      <Row className="table-lazyLoading mt-1">
        <Col md={12}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-1">
        <Col md={12}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-1">
        <Col md={12}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-1">
        <Col md={12}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
    </div>
  );
};
//create array with students data to show in the table
const dummyData = [
  {
    id: 1,
    name: "Tamara Perez",
    program_name: "Preparatoria",
    program_level: "1",
    grade: "1",
    group: "A",
    grading_periods: [
      {
        id: 1,
        score: "10",
        comment: "Muy bien",
      },
      {
        id: 2,
        score: "9",
        comment: "Bien",
      },
      {
        id: 3,
        score: "8",
        comment: "Regular",
      },
    ],
  },
  {
    id: 2,
    name: "Maria Lopez",
    program_name: "Preparatoria",
    program_level: "1",
    grade: "1",
    group: "A",
    grading_periods: [
      {
        id: 1,
        score: "10",
        comment: "Muy bien",
      },
      {
        id: 2,
        score: "9",
        comment: "Bien",
      },
      {
        id: 3,
        score: "8",
        comment: "Regular",
      },
    ],
  },
];

const StudentsAttendance = (props) => {
  const history = useHistory();
  const [tab, setTab] = useState("scores");
  const [date, setDate] = useState(new Date());
  const [classes, setClasses] = useState([]);
  const [classSelected, setClassSelected] = useState(false);
  //const [periods, setPeriods] = useState([]);
  const [periodSelected, setPeriodSelected] = useState(false);
  const [partialSelected, setPartialSelected] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(true);
  const [studentsClass, setStudentsClass] = useState([]);
  const [studentsAttendance, setStudentsAttendance] = useState([]);
  const [loadingStudentsAttendance, setLoadingStudentsAttendance] =
    useState(true);
  const [allStudentsSelected, setAllStudentsSelected] = useState(false);
  const [loadingClass, setLoadingClass] = useState(true);
  const [criteriaClass, setCriteriaClass] = useState([]);
  //const [criteriaTab, setCriteriaTab] = useState({});
  //const [secondCriteria, setSecondCriteria] = useState({});
  const [typeScore, setTypeScore] = useState("period");
  const [t] = useTranslation(["dashboard"]);
  registerLocale("spanish", es);

  /**
   * Description: Function to get students with attendance according to date
   * @param {int} classId
   * @param {string} date
   */
  const getStudentsAttendace = (classId, date) => {
    setLoadingStudentsAttendance(true);
    const request = {
      class_id: classId,
      attendance_date: getDateInUnix(date),
    };
    GetStudentsAttendanceClass(request)
      .then((result) => {
        if (result.status) {
          const students = result?.data || [];
          const hasStudents = students.length;
          students.forEach((student) => {
            student.hasAttendance = student.attendance?.value === "1";
          });
          setStudentsAttendance(students);
          // Know if all students have attendance on the selected date
          const allHasAttendance =
            hasStudents && students.every((student) => student.hasAttendance);
          setAllStudentsSelected(allHasAttendance);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        // Loading completed successfully
        setLoadingStudentsAttendance(false);
      });
  };

  /**
   * Description: Function to get scores from students class
   * @param {string} classId
   * @param {object} [filters={}]
   */
  const getStudentsClassv2 = (classId, filters = {}) => {
    const payload = {
      class_id: classId,
      calculate_avg: true,
      limit: 5,
      offset: 0,
    };
    if (filters.periodId) {
      payload.grading_period_id = filters.periodId;
    }
    if (filters.partialId) {
      payload.grading_period_partial_id = filters.partialId;
    }
    setLoadingStudents(true);
    GetStudentsClassV2_1(payload)
      .then((result) => {
        const students = result?.data || [];
        setStudentsClass(students);
        setTypeScore(filters?.partialId ? "period_partial" : "period");
        // Filter by criterias
        formatCriterias(students, filters.cleanCriterias);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  const formatCriterias = (students, cleanCriterias) => {
    let criterias = [];
    //let criteriaSelected = {};
    //let secondCriteriaSelected = {};
    if (students[0]) {
      const periods = students[0].grading_periods;
      if (periods[0]?.partials?.[0]?.criteria) {
        criterias = periods[0].partials[0].criteria;
      }
      if (periods[0]?.criteria) {
        criterias = periods[0].criteria;
      }
      // Add position for each criteria
      criterias = criterias.map((mainCriteria, mainIndex) => {
        return {
          ...mainCriteria,
          index: mainIndex,
          second_criterias: mainCriteria.second_criterias?.map(
            (secondCriteria, secondIndex) => {
              return {
                ...secondCriteria,
                index: secondIndex,
              };
            }
          ),
        };
      });
      if (criterias.length) {
        // const firstMainCriteria = criterias?.[0] || {};
        // const firstSecondCriteria =
        //   firstMainCriteria?.second_criterias?.[0] || {};
        const filters = JSON.parse(
          localStorage.getItem(`TeacherDashboardGradeFilters${prefix}`)
        );
        // Set criteria by case
        if (cleanCriterias) {
          // criteriaSelected = firstMainCriteria;
          // secondCriteriaSelected = firstSecondCriteria;
        }
        // Check if the first criteria ID is provided in the filters
        else if (
          filters?.firstCriteriaId ||
          getUrlParamValue("firstCriteriaId")
        ) {
          // Find the main criteria that matches the firstCriteriaId from the filters
          // const mainCriteriaPreselected = criterias.find(
          //   (criteria) =>
          //     criteria.grading_criterion_class_id == filters?.firstCriteriaId ||
          //     criteria.grading_criterion_class_id ==
          //       getUrlParamValue("firstCriteriaId")
          // );
          // If found, use the preselected criteria; otherwise, fallback to the first main criteria
          // criteriaSelected = mainCriteriaPreselected || firstMainCriteria;
          // If the selected criteria has second-level criteria
          // if (criteriaSelected?.second_criterias?.length) {
          //   // Check if the second criteria ID is provided in the filters
          //   secondCriteriaSelected =
          //     filters?.secondCriteriaID || getUrlParamValue("secondCriteriaID")
          //       ? criteriaSelected.second_criterias.find(
          //           (criteria) =>
          //             criteria.grading_criterion_class_id ==
          //               filters?.secondCriteriaID ||
          //             criteria.grading_criterion_class_id ==
          //               getUrlParamValue("secondCriteriaID")
          //         ) || criteriaSelected.second_criterias[0]
          //       : criteriaSelected.second_criterias[0];
          // }
          // Clear query params from URL
          removeUrlParams(["firstCriteriaId", "secondCriteriaID"]);
        } else {
          // Use the criteria from the tab or fallback to the first main criteria
          // criteriaSelected =
          //   criteriaTab && Object.keys(criteriaTab).length > 0
          //     ? criteriaTab
          //     : firstMainCriteria;
          // Use the second criteria from the tab or fallback to the first second-level criteria
          // secondCriteriaSelected =
          //   secondCriteria && Object.keys(secondCriteria).length > 0
          //     ? secondCriteria
          //     : firstSecondCriteria;
        }
        // Set type score state
        setTypeScore(
          periods[0]?.partials?.[0]
            ? "period_partial_criterion"
            : "period_criterion"
        );
      }
    }
    // Update criterias state
    //setCriteriaTab(criteriaSelected);
    //setSecondCriteria(secondCriteriaSelected);
    setCriteriaClass(criterias);
    // Clear filters from localStorage
    localStorage.removeItem(`TeacherDashboardGradeFilters${prefix}`);
    // Loading completed successfully
    setLoadingStudents(false);
  };

  /**
   * Description: Function to update the attendance of all students in the class.
   */
  const selectAllStudents = () => {
    const students = [...studentsAttendance];
    // update the value of attendance for all students locally
    students.forEach((student) => {
      student.hasAttendance = !allStudentsSelected;
    });
    setStudentsAttendance(students);
    setAllStudentsSelected(!allStudentsSelected);
    // update the value of attendance for all students in the database
    updateStudentsAttendance(!allStudentsSelected);
  };

  /**
   * Description: Function to update the attendance of a student in the class.
   * @param {int} studentId
   * @param {boolean} checked
   */
  const selectStudent = (studentId, checked) => {
    const students = [...studentsAttendance];
    // locally update a student's attendance
    const studentIndex = students.findIndex(
      (student) => student.student_id == studentId
    );
    students[studentIndex].hasAttendance = checked;
    setStudentsAttendance(students);
    // Know if all students are selected
    const allStudentsSelected =
      students.length && students.every((student) => student.hasAttendance);
    setAllStudentsSelected(allStudentsSelected);
    // Update attendance in the db
    updateStudentsAttendance(checked, [studentId]);
  };

  /**
   * Description: Function to update student attendance
   * @param {boolean} attendace
   * @param {array} studentIds
   */
  const updateStudentsAttendance = (attendace, studentIds) => {
    const value = attendace ? "1" : "0";
    const request = {
      class_id: classSelected.value,
      date: getDateInUnix(),
      value,
    };
    if (studentIds) {
      request.student_ids = studentIds;
    }
    CreateMultipleAttendance(request)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  /**
   * Description: Function to get a date in UNIX format
   * @param {string} dateParam
   * @return {unix} Timestamp
   */
  const getDateInUnix = (dateParam) => {
    const newDate = dateParam || date;
    const dateString = newDate.toDateString();
    const dateUnixMiliseconds = new Date(dateString).getTime();
    const dateUnixSeconds = dateUnixMiliseconds / 1000;
    return dateUnixSeconds;
  };

  /**
   * Description: Function to change value for score or comment
   * @param {string} property score|comment
   * @param {string} value
   * @param {string} studentClassId
   */
  const changeStudentGrades = (
    property,
    value,
    studentClassId,
    posCriteria
  ) => {
    //identify the student and the period
    const scoreConfig = classSelected.score_config;
    const students = [...studentsClass];
    const studentIndex = students.findIndex(
      (student) => student.student_class_id === studentClassId
    );
    const student = students[studentIndex];
    if (property === "score") {
      // Validate value according score system
      const regexLetter = /[A-Za-z ñ]/;
      const regexInteger = /^([0-9]|[1-9][0-9]|100)$/;
      const regexDecimal =
        scoreConfig.grading_precision == "1"
          ? /^(\d{0,2}(\.\d{0,1})?|100(\.00?)?)$/
          : /^(\d{0,2}(\.\d{0,2})?|100(\.00?)?)$/;
      const regexNumeric =
        scoreConfig.grading_period_number_format === "integer"
          ? regexInteger
          : regexDecimal;
      const REGEX =
        scoreConfig.score_system === "customized" ? regexLetter : regexNumeric;
      // Value Assignment
      if (REGEX.test(value) || value === "") {
        const { grading_periods } = student;
        const period = grading_periods[0];
        const partial = period.partials?.[0];
        // const criteriaPos = criteriaTab?.index;
        // const secondCriteriaPos = secondCriteria?.index;
        const targets = {
          period_partial_criterion: partial?.criteria?.[posCriteria],
          // secondCriteria?.name
          //   ? partial?.criteria?.[criteriaPos]?.second_criterias?.[
          //       secondCriteriaPos
          //     ]
          //   : partial?.criteria?.[criteriaPos],
          period_criterion: period?.criteria?.[posCriteria],
          // secondCriteria?.name
          //   ? period.criteria?.[criteriaPos]?.second_criterias?.[
          //       secondCriteriaPos
          //     ]
          //   : period.criteria?.[criteriaPos],
          period_partial: partial,
          period,
        };
        targets[typeScore][property] = value;
      }
    } else {
      //add comment to student
      student.grading_periods[0][property] = value;
    }
    students[studentIndex] = student;
    setStudentsClass(students);
  };

  /**
   * Description: Function to set student grade
   * @param {string} score
   * @param {string} studentClassId
   * @param {string} scoreId
   */
  const setStudentGrade = (
    score,
    studentClassId,
    scoreId,
    studentIndex,
    criteria
  ) => {
    score = score === "" ? null : score;
    const payload = {
      type: typeScore,
      calculate_avg: true,
      grading_period_id: periodSelected.value,
      students_score: [
        {
          student_class_id: studentClassId,
          student_id: studentsClass[studentIndex].student_id,
          grading_period_id: periodSelected.value,
          scores: [{ score }],
        },
      ],
    };
    // Add partial
    if (typeScore != "period" && partialSelected) {
      payload.students_score[0].grading_period_partial_id =
        partialSelected.value;
      payload.grading_period_partial_id = partialSelected.value;
    }
    // Add grading_criterion_class_id
    if (
      criteria &&
      (typeScore == "period_criterion" ||
        typeScore == "period_partial_criterion")
    ) {
      payload.students_score[0].scores[0].grading_criterion_class_id =
        criteria.grading_criterion_class_id;
      if (criteria.grading_criterion_score_id) {
        payload.students_score[0].scores[0].grading_criterion_score_id =
          criteria.grading_criterion_score_id;
      }
      // secondCriteria?.grading_criterion_class_id ||
      // criteriaTab.grading_criterion_class_id;
    }
    // If has score id then update else create new score
    if (scoreId) {
      payload.students_score[0].scores[0].score_id = scoreId;
    }
    createStudentScore(payload, studentIndex);
  };

  /**
   * Description: Function to create a student's score
   * @param {object} request
   */
  const createStudentScore = (request, studentIndex) => {
    SaveStudentsScore(request)
      .then((response) => {
        if (response.status) {
          let students = [...studentsClass];
          students[studentIndex].average = response.data[0].average;
          setStudentsClass(students);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  /**
   * Description: Function to create a student's observation
   * @param {string} comment
   * @param {string} studentClassId
   */
  const createStudentObservation = (comment, studentClassId) => {
    const request = {
      comment,
      grading_period_id: periodSelected.value,
      student_class_id: studentClassId,
    };
    CreateStudentComment(request)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  /**
   * Description: Function to get the list of classes
   */
  const getFilterLists = () => {
    GetTeacherClassesList()
      .then((result) => {
        const classes = result.data.map((item) => {
          return {
            label: item.class_title,
            value: item.id,
            score_config: item.score_config,
            periods: item.periods.map((period, index) => {
              return {
                value: period.grading_period_id,
                label: `${t("textStudents.period")} ${index + 1}`,
                partials: period.partials.map((partial, PartialIndex) => {
                  return {
                    value: partial,
                    label: `${t("textStudents.partial")} ${PartialIndex + 1}`,
                    position: PartialIndex,
                  };
                }),
                index,
              };
            }),
          };
        });
        setClasses(classes);
        // Evaluate query parameters
        if (classes?.length) {
          evaluateParams(classes);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingClass(false);
      });
  };

  /**
   * Description: Function to assign values for the main selectors
   * @param {array} [classes=[]]
   */
  const evaluateParams = (classes = []) => {
    // Get URL parameter values
    const classId = getUrlParamValue("classId");
    const periodId = getUrlParamValue("periodId");
    const partialId = getUrlParamValue("partialId");

    // Get filters from localStorage
    const storageFiltersJSON = localStorage.getItem(
      `TeacherDashboardGradeFilters${prefix}`
    );
    const storageFilters = JSON.parse(storageFiltersJSON);
    // Find the selected class or assign the first one
    const selectedClass =
      classes.find(
        (item) => item.value == (classId || storageFilters?.classId)
      ) || classes[0];
    // Find the selected period or assign the first one
    const selectedPeriod =
      selectedClass?.periods?.find(
        (item) => item.value == (periodId || storageFilters?.periodId)
      ) || selectedClass?.periods?.[0];
    // Find the selected partial or assign the first one
    const selectedPartial =
      selectedPeriod?.partials?.find(
        (item) => item.value == (partialId || storageFilters?.partialId)
      ) || selectedPeriod?.partials?.[0];

    // Update state
    setClassSelected(selectedClass);
    setPeriodSelected(selectedPeriod);
    setPartialSelected(selectedPartial);

    // Clear query params from URL
    removeUrlParams(["classId", "periodId", "partialId"]);

    // Get the students of the selected class
    if (selectedClass?.value) {
      const filters = {};
      if (selectedPartial) {
        filters.partialId = selectedPartial.value;
        filters.periodId = selectedPeriod.value;
      } else {
        filters.periodId = selectedPeriod.value;
      }
      getStudentsClassv2(selectedClass.value, filters);
      getStudentsAttendace(selectedClass.value, date);
    }
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    if (props.active == false) {
      setStudentsClass(dummyData);
      setClassSelected(true);
      setLoadingClass(false);
    } else {
      getFilterLists();
    }
  }, [props.active]);

  /**
   * Description: Function to save current filters
   */
  const saveFilters = () => {
    // Create a object of the current filters
    const filters = {
      classId: classSelected?.value,
      periodId: periodSelected?.value,
      partialId: partialSelected?.value,
      //firstCriteriaId: criteriaTab?.grading_criterion_class_id,
      //secondCriteriaID: secondCriteria?.grading_criterion_class_id,
    };
    // Save filters in local storage
    localStorage.setItem(
      `TeacherDashboardGradeFilters${prefix}`,
      JSON.stringify(filters)
    );
  };

  /**
   * Description: Function to get the filters and format them as query params
   * @return {string} query params filters
   */
  const getQueryParamsFilters = () => {
    let filters = "&";
    // Validate filters
    if (classSelected?.value) filters += `classId=${classSelected.value}&`;
    if (periodSelected?.value) filters += `periodId=${periodSelected.value}&`;
    if (partialSelected?.value)
      filters += `partialId=${partialSelected.value}&`;
    // if (criteriaTab?.grading_criterion_class_id)
    //   filters += `firstCriteriaId=${criteriaTab.grading_criterion_class_id}&`;
    // if (secondCriteria?.grading_criterion_class_id)
    //   filters += `secondCriteriaID=${secondCriteria.grading_criterion_class_id}&`;
    // Remove the last "&" and build the final URL
    filters = filters.slice(0, -1);
    return filters;
  };

  const getScore = (student) => {
    const { grading_periods } = student;
    const period = grading_periods[0];
    const partial = period.partials?.[0];
    // const criteriaPos = criteriaTab?.index;
    // const secondCriteriaPos = secondCriteria?.index;

    const targets = {
      period_partial_criterion: partial?.criteria,
      // secondCriteria?.name
      //   ? partial?.criteria?.[criteriaPos]?.second_criterias?.[
      //       secondCriteriaPos
      //     ]
      //   : partial?.criteria?.[criteriaPos],
      period_criterion: period?.criteria,
      // secondCriteria?.name
      //   ? period.criteria?.[criteriaPos]?.second_criterias?.[secondCriteriaPos]
      //   : period.criteria?.[criteriaPos],
      period_partial: partial,
      period,
    };

    return targets[typeScore];
  };

  /**
   * Description:
   * @param {object} [mainCriteria={}]
   * @return {JSX}
   */
  // TODO: Remove this when it is no longer needed
  // const studentsTable = (mainCriteria = {}) => {
  //   return (
  //     <Table
  //       bordered
  //       responsive
  //       style={{
  //         borderTop: "white",
  //         borderBottom: "solid 1px #dee2e6",
  //       }}
  //     >
  //       <thead style={{ borderBottom: "solid 1px #dee2e6" }}>
  //         {/** Row with gear icon for redirect to criterias */}
  //         <tr>
  //           <th colSpan="4">
  //             {/**TODO: Agregar el search de estudiantes */}
  //             {/** Second level criteria selector */}
  //             {mainCriteria?.second_criterias && (
  //               <Dropdown style={customStyles.dropDownCriteria}>
  //                 <Dropdown.Toggle
  //                   className="text-truncate"
  //                   id="secondCriteria"
  //                   variant="outline-secondary"
  //                 >
  //                   {secondCriteria?.name}
  //                 </Dropdown.Toggle>
  //                 <Dropdown.Menu>
  //                   {mainCriteria.second_criterias?.map((criteria) => {
  //                     return (
  //                       <Dropdown.Item
  //                         key={criteria.grading_criterion_class_id}
  //                         onClick={() => {
  //                           setSecondCriteria(criteria);
  //                         }}
  //                       >
  //                         {criteria.name}
  //                       </Dropdown.Item>
  //                     );
  //                   })}
  //                 </Dropdown.Menu>
  //               </Dropdown>
  //             )}
  //             {/** Configurar criterios de evaluación */}
  //             <OverlayTrigger
  //               placement="top"
  //               overlay={
  //                 <Tooltip id="customizedCriteriaTooltip">
  //                   {t("textStudents.customizeEvaluationCriteria")}
  //                 </Tooltip>
  //               }
  //             >
  //               <div
  //                 style={customStyles.biGearIcon}
  //                 onClick={() => {
  //                   saveFilters();
  //                   const filters = getQueryParamsFilters();
  //                   history.push(
  //                     `/capture_grades?addCriterias=${true}${filters}`
  //                   );
  //                 }}
  //               >
  //                 <i className="bi bi-gear fa-lg"></i>
  //               </div>
  //             </OverlayTrigger>
  //           </th>
  //         </tr>
  //         {/** Row with headers */}
  //         <tr>
  //           {/** Student name header */}
  //           <th width="40%">{t("textStudents.studentName")}</th>
  //           {/** Average header */}
  //           <th width="10%" className="text-center">
  //             {t("textStudents.average")}
  //           </th>
  //           {/** Score header */}
  //           <th width="20%" className="text-center">
  //             {/** Score concept name  */}
  //             {secondCriteria?.name ||
  //               mainCriteria?.name ||
  //               partialSelected?.label ||
  //               periodSelected?.label ||
  //               t("textStudents.grade")}
  //             {/** Score percentage only for criterias */}
  //             {mainCriteria?.percentage > 0 && (
  //               <span style={{ color: "#ea2c54" }}>{` ${
  //                 secondCriteria?.percentage || mainCriteria.percentage
  //               }% / ${mainCriteria.percentage}% `}</span>
  //             )}
  //             {/** Tooltip only for criterias no evaluables */}
  //             {mainCriteria?.name && mainCriteria?.evaluable != 1 && (
  //               <span className="ms-2">
  //                 <OverlayTrigger
  //                   placement="top"
  //                   overlay={
  //                     <Tooltip id="criteriaNoEvaluable">
  //                       {t("textStudents.criteriaNoEvaluable")}
  //                     </Tooltip>
  //                   }
  //                 >
  //                   <i className="fas fa-info-circle" />
  //                 </OverlayTrigger>
  //               </span>
  //             )}
  //           </th>
  //           {/** Commnets header */}
  //           <th width="30%" className="text-center">
  //             {t("textStudents.observations")}
  //           </th>
  //         </tr>
  //       </thead>
  //       <tbody style={{ borderTop: "1px" }}>
  //         <tr>
  //           <td colSpan="4" style={{ padding: 0 }}>
  //             <div
  //               style={{
  //                 ...customStyles.overflowScrollY,
  //                 maxHeight: mainCriteria?.name ? "101px" : "140px",
  //               }}
  //               className="scrollbar-hidden"
  //             >
  //               {/** Students list */}
  //               <Table bordered responsive style={{ marginBottom: 0 }}>
  //                 <tbody>
  //                   {studentsClass.map((student, studentIndex) => {
  //                     const studentScore = getScore(student);
  //                     return (
  //                       <tr key={student.id} className="mb-2">
  //                         {/** Student name */}
  //                         <td width="40%">
  //                           {`${student.student_name} - `}
  //                           {student.program_name
  //                             ? `(${student.program_name} ${student.program_level})`
  //                             : `(${student.grade}${student.group})`}
  //                         </td>
  //                         {/** Student average */}
  //                         <td width="10%" className="text-center">
  //                           {student.average}
  //                         </td>
  //                         {/** Student score */}
  //                         <td width="20%" className="text-center">
  //                           {classSelected.score_config.score_system ===
  //                           "customized" ? (
  //                             <Dropdown
  //                               id="score"
  //                               onSelect={(value) => {
  //                                 value = value != 0 ? value : "";
  //                                 changeStudentGrades(
  //                                   "score",
  //                                   value,
  //                                   student.student_class_id
  //                                 );
  //                                 setStudentGrade(
  //                                   value,
  //                                   student.student_class_id,
  //                                   studentScore.score_id,
  //                                   studentIndex
  //                                 );
  //                               }}
  //                             >
  //                               <Dropdown.Toggle
  //                                 variant="outline-secondary"
  //                                 className="text-truncate"
  //                               >
  //                                 {studentScore?.score || ""}
  //                               </Dropdown.Toggle>
  //                               <Dropdown.Menu style={{ minWidth: "50px" }}>
  //                                 <Dropdown.Item
  //                                   className="removeElementDropdown"
  //                                   eventKey={0}
  //                                   style={{
  //                                     color: "red",
  //                                     textAlign: "center",
  //                                     fontSize: "14px",
  //                                     fontWeight: "bold",
  //                                   }}
  //                                 >
  //                                   X
  //                                 </Dropdown.Item>
  //                                 {classSelected.score_config.equivalences.map(
  //                                   (equivalence) => {
  //                                     return (
  //                                       <Dropdown.Item
  //                                         key={equivalence.id}
  //                                         eventKey={equivalence.score_letter}
  //                                         style={{
  //                                           textAlign: "center",
  //                                         }}
  //                                       >
  //                                         {equivalence.score_letter}
  //                                       </Dropdown.Item>
  //                                     );
  //                                   }
  //                                 )}
  //                               </Dropdown.Menu>
  //                             </Dropdown>
  //                           ) : (
  //                             <FormControl
  //                               className="border-0 text-center"
  //                               id={`score${student.student_id}`}
  //                               onBlur={(e) =>
  //                                 setStudentGrade(
  //                                   e.target.value,
  //                                   student.student_class_id,
  //                                   studentScore.score_id,
  //                                   studentIndex
  //                                 )
  //                               }
  //                               onChange={(e) =>
  //                                 changeStudentGrades(
  //                                   "score",
  //                                   e.target.value,
  //                                   student.student_class_id
  //                                 )
  //                               }
  //                               onKeyDown={(e) => {
  //                                 if (e.key === "Enter") {
  //                                   const inputSelected =
  //                                     document.getElementById(
  //                                       `score${student.student_id}`
  //                                     );
  //                                   inputSelected.blur();
  //                                 }
  //                               }}
  //                               value={studentScore?.score || ""}
  //                             />
  //                           )}
  //                         </td>
  //                         {/** Student comment */}
  //                         <td width="30%">
  //                           <FormControl
  //                             className="border-0"
  //                             id="observation"
  //                             onBlur={(e) =>
  //                               createStudentObservation(
  //                                 e.target.value,
  //                                 student.student_class_id
  //                               )
  //                             }
  //                             onChange={(e) =>
  //                               changeStudentGrades(
  //                                 "comment",
  //                                 e.target.value,
  //                                 student.student_class_id
  //                               )
  //                             }
  //                             value={student.grading_periods[0]?.comment || ""}
  //                             maxLength={
  //                               classSelected?.periods.length > 10
  //                                 ? "150"
  //                                 : "250"
  //                             }
  //                           />
  //                         </td>
  //                       </tr>
  //                     );
  //                   })}
  //                 </tbody>
  //               </Table>
  //             </div>
  //           </td>
  //         </tr>
  //       </tbody>
  //     </Table>
  //   );
  // };

  const studentsTable = (mainCriteria = {}) => {
    return (
      <Table
        bordered
        responsive
        style={{
          borderTop: "white",
          borderBottom: "solid 1px #dee2e6",
        }}
      >
        <thead style={{ borderBottom: "solid 1px #dee2e6" }}>
          {/** Row with gear icon for redirect to criterias */}
          <tr>
            <th
              colSpan={criteriaClass.length > 0 ? 2 + criteriaClass.length : 3}
            >
              {/**TODO: Agregar el search de estudiantes */}
              {/** Configurar criterios de evaluación */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="customizedCriteriaTooltip">
                    {t("textStudents.customizeEvaluationCriteria")}
                  </Tooltip>
                }
              >
                <div
                  style={customStyles.biGearIcon}
                  onClick={() => {
                    saveFilters();
                    const filters = getQueryParamsFilters();
                    history.push(
                      `/capture_grades?addCriterias=${true}${filters}`
                    );
                  }}
                >
                  <i className="bi bi-gear fa-lg"></i>
                </div>
              </OverlayTrigger>
            </th>
          </tr>
          {/** Row with headers */}
          <tr>
            {/** Student name header */}
            <th width="25%">{t("textStudents.studentName")}</th>
            {/** Score header */}
            {criteriaClass.length > 0 ? (
              criteriaClass.map((criteria) => {
                return (
                  <th
                    key={criteria.index}
                    width={50 / criteriaClass.length + "%"}
                    className="text-center"
                  >
                    {criteria.name}
                  </th>
                );
              })
            ) : (
              <th width="50%" className="text-center">
                {/** Score concept name  */}
                {partialSelected?.label ||
                  periodSelected?.label ||
                  t("textStudents.grade")}
              </th>
            )}
            {/** Report card header */}
            <th width="25%" className="text-center">
              {t("textStudents.observations")}
            </th>
          </tr>
        </thead>
        <tbody style={{ borderTop: "1px" }}>
          <tr>
            <td
              colSpan={criteriaClass.length > 0 ? 2 + criteriaClass.length : 3}
              style={{ padding: 0 }}
            >
              <div
                style={{
                  ...customStyles.overflowScrollY,
                  maxHeight: mainCriteria?.name ? "101px" : "140px",
                }}
                className="scrollbar-hidden"
              >
                {/** Students list */}
                <Table bordered responsive style={{ marginBottom: 0 }}>
                  <tbody>
                    {studentsClass.map((student, studentIndex) => {
                      const studentScore = getScore(student);
                      return (
                        <tr key={student.id} className="mb-2">
                          {/** Student name */}
                          <td width="25%">
                            {`${student.student_name} - `}
                            {student.program_name
                              ? `(${student.program_name} ${student.program_level})`
                              : `(${student.grade}${student.group})`}
                          </td>
                          {/** Student score */}
                          {criteriaClass.length > 0 ? (
                            criteriaClass.map((criteria) => {
                              return (
                                <td
                                  key={criteria.index}
                                  width={50 / criteriaClass.length + "%"}
                                  className="text-center"
                                >
                                  {classSelected.score_config.score_system ===
                                  "customized" ? (
                                    <Dropdown
                                      id="score"
                                      onSelect={(value) => {
                                        value = value != 0 ? value : "";
                                        changeStudentGrades(
                                          "score",
                                          value,
                                          student.student_class_id,
                                          criteria.index
                                        );
                                        setStudentGrade(
                                          value,
                                          student.student_class_id,
                                          false,
                                          studentIndex,
                                          studentScore?.[criteria.index]
                                        );
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="outline-secondary"
                                        className="text-truncate"
                                      >
                                        {studentScore?.[criteria.index]
                                          ?.score || ""}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        style={{ minWidth: "50px" }}
                                      >
                                        <Dropdown.Item
                                          className="removeElementDropdown"
                                          eventKey={0}
                                          style={{
                                            color: "red",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          X
                                        </Dropdown.Item>
                                        {classSelected.score_config.equivalences.map(
                                          (equivalence) => {
                                            return (
                                              <Dropdown.Item
                                                key={equivalence.id}
                                                eventKey={
                                                  equivalence.score_letter
                                                }
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {equivalence.score_letter}
                                              </Dropdown.Item>
                                            );
                                          }
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    <FormControl
                                      className="border-0 text-center"
                                      id={`score${student.student_id}`}
                                      onBlur={(e) =>
                                        setStudentGrade(
                                          e.target.value,
                                          student.student_class_id,
                                          false,
                                          studentIndex,
                                          studentScore?.[criteria.index]
                                        )
                                      }
                                      onChange={(e) =>
                                        changeStudentGrades(
                                          "score",
                                          e.target.value,
                                          student.student_class_id,
                                          criteria.index
                                        )
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          const inputSelected =
                                            document.getElementById(
                                              `score${student.student_id}`
                                            );
                                          inputSelected.blur();
                                        }
                                      }}
                                      value={
                                        studentScore?.[criteria.index]?.score ||
                                        ""
                                      }
                                    />
                                  )}
                                </td>
                              );
                            })
                          ) : (
                            <td width="50%" className="text-center">
                              {classSelected.score_config.score_system ===
                              "customized" ? (
                                <Dropdown
                                  id="score"
                                  onSelect={(value) => {
                                    value = value != 0 ? value : "";
                                    changeStudentGrades(
                                      "score",
                                      value,
                                      student.student_class_id
                                    );
                                    setStudentGrade(
                                      value,
                                      student.student_class_id,
                                      studentScore.score_id,
                                      studentIndex
                                    );
                                  }}
                                >
                                  <Dropdown.Toggle
                                    variant="outline-secondary"
                                    className="text-truncate"
                                  >
                                    {studentScore?.score || ""}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu style={{ minWidth: "50px" }}>
                                    <Dropdown.Item
                                      className="removeElementDropdown"
                                      eventKey={0}
                                      style={{
                                        color: "red",
                                        textAlign: "center",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      X
                                    </Dropdown.Item>
                                    {classSelected.score_config.equivalences.map(
                                      (equivalence) => {
                                        return (
                                          <Dropdown.Item
                                            key={equivalence.id}
                                            eventKey={equivalence.score_letter}
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {equivalence.score_letter}
                                          </Dropdown.Item>
                                        );
                                      }
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                <FormControl
                                  className="border-0 text-center"
                                  id={`score${student.student_id}`}
                                  onBlur={(e) =>
                                    setStudentGrade(
                                      e.target.value,
                                      student.student_class_id,
                                      studentScore.score_id,
                                      studentIndex
                                    )
                                  }
                                  onChange={(e) =>
                                    changeStudentGrades(
                                      "score",
                                      e.target.value,
                                      student.student_class_id
                                    )
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      const inputSelected =
                                        document.getElementById(
                                          `score${student.student_id}`
                                        );
                                      inputSelected.blur();
                                    }
                                  }}
                                  value={studentScore?.score || ""}
                                />
                              )}
                            </td>
                          )}

                          {/** Student comment */}
                          <td width="25%">
                            <FormControl
                              className="border-0"
                              id="observation"
                              onBlur={(e) =>
                                createStudentObservation(
                                  e.target.value,
                                  student.student_class_id
                                )
                              }
                              onChange={(e) =>
                                changeStudentGrades(
                                  "comment",
                                  e.target.value,
                                  student.student_class_id
                                )
                              }
                              value={student.grading_periods[0]?.comment || ""}
                              maxLength={
                                classSelected?.periods.length > 10
                                  ? "150"
                                  : "250"
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <>
      {/** Title and Filters*/}
      <Row className="mt-3">
        {/** Title */}
        <Col xs={12} md={5} lg={6} xl={8}>
          <i className="bi bi-briefcase me-2 fs-6" />
          <b className="fs-6">{t("textStudents.title")}</b>
        </Col>
        {/** Filters */}
        <Col xs={12} md={7} lg={6} xl={4} className="d-flex flex-row-reverse">
          {tab === "scores" ? (
            <>
              <FormGroup>
                <FormLabel className="mb-0">
                  {t("textStudents.partial")}:
                </FormLabel>
                <Dropdown>
                  <Dropdown.Toggle
                    className="text-truncate"
                    id="periods"
                    variant="outline-secondary"
                    disabled={
                      !periodSelected?.partials ||
                      periodSelected.partials.length === 0
                    }
                  >
                    {partialSelected
                      ? partialSelected.label
                      : t("textStudents.noPartial")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {periodSelected.partials?.map((partial) => {
                      return (
                        <Dropdown.Item
                          key={partial.value}
                          onClick={() => {
                            setPartialSelected(partial);
                            const filters = {
                              periodId: periodSelected.value,
                              partialId: partial.value,
                            };
                            getStudentsClassv2(classSelected.value, filters);
                          }}
                        >
                          {partial.label}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </FormGroup>

              <FormGroup className="me-3">
                <FormLabel className="mb-0">
                  {t("textStudents.period")}:
                </FormLabel>
                <Dropdown>
                  <Dropdown.Toggle
                    className="text-truncate"
                    id="periods"
                    variant="outline-secondary"
                    disabled={!classSelected}
                  >
                    {periodSelected?.label
                      ? periodSelected.label
                      : t("textStudents.period")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {classSelected &&
                      classSelected.periods?.map((period) => {
                        return (
                          <Dropdown.Item
                            key={period.value}
                            onClick={() => {
                              const filters = {};
                              if (period.partials?.[0]) {
                                filters.partialId = period.partials?.[0].value;
                                filters.periodId = period.value;
                              } else {
                                filters.periodId = period.value;
                              }
                              setPeriodSelected(period);
                              setPartialSelected(period.partials?.[0]);
                              getStudentsClassv2(classSelected.value, filters);
                            }}
                          >
                            {period.label}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </FormGroup>
            </>
          ) : (
            <FormGroup>
              <FormLabel className="mb-0">{t("textStudents.date")}:</FormLabel>
              <div className="me-0">
                <DateView
                  selected={date}
                  onChange={(e) => {
                    setDate(e);
                    getStudentsAttendace(classSelected.value, e);
                  }}
                  dateFormat="dd/MM/yyyy"
                  locale="spanish"
                  className="inputDatePicker"
                  disabled={!classSelected}
                />
              </div>
            </FormGroup>
          )}
          <FormGroup className="me-3">
            <FormLabel className="mb-0">{t("textStudents.class")}: </FormLabel>
            <Dropdown>
              <Dropdown.Toggle
                className="text-truncate"
                id="classes"
                variant="outline-secondary"
                disabled={loadingClass}
              >
                {loadingClass ? (
                  <TextLoading text={t("textStudents.selectClass")} />
                ) : (
                  classSelected?.label || t("textStudents.selectClass")
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ maxHeight: "200px", overflow: "auto" }}>
                {classes.map((item) => {
                  return (
                    <Dropdown.Item
                      key={item.value}
                      onClick={() => {
                        const filters = {};
                        if (item.periods[0].partials?.[0]) {
                          filters.partialId = item.periods[0].partials[0].value;
                          filters.periodId = item.periods[0].value;
                        } else {
                          filters.periodId = item.periods[0].value;
                        }
                        filters.cleanCriterias = true;
                        const firstPeriod = item.periods[0];
                        setPeriodSelected(firstPeriod);
                        setPartialSelected(firstPeriod.partials?.[0]);
                        setClassSelected(item);
                        getStudentsClassv2(item.value, filters);
                        getStudentsAttendace(item.value, date);
                      }}
                      className="text-truncate"
                    >
                      {item.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </FormGroup>
        </Col>
      </Row>
      {/** Tabs with tables */}
      <Row className="mt-0">
        {/** Tabs */}
        <Col>
          <Tabs
            defaultActiveKey={tab}
            id="uncontrolled-tab-example"
            onSelect={(tab) => setTab(tab)}
          >
            {/** SCORES TAB */}
            <Tab eventKey="scores" title={t("textStudents.grades")}>
              {loadingStudents ? (
                lazyLoadingTable()
              ) : classSelected ? (
                periodSelected && (
                  /** Grade table for the selected period */
                  <div>
                    {/* {criteriaClass.length ? (
                      <Tabs
                        activeKey={criteriaTab.grading_criterion_class_id}
                        className="mt-1"
                        onSelect={(e) => {
                          const criteria = criteriaClass.find(
                            (criteria) =>
                              criteria.grading_criterion_class_id == e
                          );
                          setCriteriaTab(criteria);
                          setSecondCriteria(
                            criteria.second_criterias?.[0] || {}
                          );
                        }}
                      >
                        {criteriaClass.map((criteria) => {
                          return (
                            <Tab
                              key={criteria.grading_criterion_class_id}
                              eventKey={criteria.grading_criterion_class_id}
                              title={criteria.name}
                            >
                              {criteriaTab.grading_criterion_class_id ==
                                criteria.grading_criterion_class_id &&
                                studentsTable(criteria)}
                            </Tab>
                          );
                        })}
                      </Tabs>
                    ) : (
                      studentsTable()
                    )} */}
                    {studentsTable()}
                  </div>
                )
              ) : (
                <p className="d-flex justify-content-center mt-5">
                  <b style={{ color: "#aaaaaa" }}>
                    {t("textStudents.noClassSelected2")}
                  </b>
                </p>
              )}
            </Tab>
            {/** ATTENDACE TAB */}
            <Tab eventKey="attendace" title={t("textStudents.attendance")}>
              {loadingStudentsAttendance ? (
                lazyLoadingTable()
              ) : classSelected ? (
                <div>
                  <Table
                    bordered
                    responsive
                    style={{
                      borderTop: "white",
                      borderBottom: "solid 1px #dee2e6",
                    }}
                  >
                    <thead
                      className="mt-2 mb-2"
                      style={{ borderBottom: "solid 1px #dee2e6" }}
                    >
                      <tr>
                        <th width="89.5%">{t("textStudents.studentName")}</th>
                        <th width="10%">
                          <div className="d-flex justify-content-center">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className="color-white"
                                  id="selectAllClasses"
                                >
                                  {t("textStudents.selectAll")}
                                </Tooltip>
                              }
                            >
                              <FormCheck
                                aria-label="selectAllStudents"
                                checked={allStudentsSelected}
                                onChange={selectAllStudents}
                                style={customStyles.checkBox}
                                disabled={!studentsClass.length}
                              />
                            </OverlayTrigger>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ borderTop: "1px" }}>
                      <tr>
                        <td colSpan="2" style={{ padding: 0 }}>
                          <div
                            style={{
                              ...customStyles.overflowScrollY,
                              maxHeight: "221px",
                            }}
                            className="scrollbar-hidden"
                          >
                            <Table
                              bordered
                              responsive
                              style={{ marginBottom: 0 }}
                            >
                              <tbody>
                                {studentsAttendance.map((student) => {
                                  return (
                                    <tr
                                      key={student.student_id}
                                      className="mb-2"
                                      style={{ borderWidth: "0px 0" }}
                                    >
                                      <td
                                        width="90%"
                                        style={{ borderLeft: "none" }}
                                      >
                                        {/* If the user is a teacher, they can access the student profile from the dashboard */}
                                        {validateUserRoleIds(7) ? (
                                          <a
                                            href={`${process.env.REACT_APP_V1}/estudiante/${student.student_id}`}
                                          >
                                            {`${student.student_name} - `}
                                            {student.program_name
                                              ? `(${student.program_name} ${student.program_level})`
                                              : `(${student.grade}${student.group})`}
                                          </a>
                                        ) : (
                                          `${student.student_name} - ` +
                                          (student.program_name
                                            ? `(${student.program_name} ${student.program_level})`
                                            : `(${student.grade}${student.group})`)
                                        )}
                                      </td>
                                      <td
                                        width="10%"
                                        style={{ borderRight: "none" }}
                                      >
                                        <div className="d-flex justify-content-center">
                                          <FormCheck
                                            aria-label="selectStudent"
                                            checked={student.hasAttendance}
                                            onChange={() =>
                                              selectStudent(
                                                student.student_id,
                                                !student.hasAttendance
                                              )
                                            }
                                            style={customStyles.checkBox}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : (
                <p className="d-flex justify-content-center mt-5">
                  <b style={{ color: "#aaaaaa" }}>
                    {t("textStudents.noClassSelected")}
                  </b>
                </p>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
      {/** Redirect to capture grades view when the tab is scores*/}
      {tab === "scores" && (
        <Row
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
        >
          <Col>
            <div onClick={saveFilters}>
              <SeeAllModule
                customText={t("dashboard:textStudents.captureGradesLink")}
                path={`/capture_grades?${getQueryParamsFilters()}`}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default StudentsAttendance;
StudentsAttendance.propTypes = {
  active: PropTypes.bool,
};
