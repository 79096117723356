import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import FieldInput from "../global/form/FieldInput";
import { Formik } from "formik";
import { ReactSortable } from "react-sortablejs";

//Custom styles
const customStyles = {
  elementsItem: {
    border: "solid 1px #cccccc",
    borderRadius: "8px",
  },
  pushButtonIcons: {
    cursor: "n-resize",
  },
  noPaddingIcons: {
    padding: "0px",
  },
  removeElement: {
    fontSize: "13px",
    padding: "4px", //remove button
    position: "relative",
    top: "-15px",
    cursor: "pointer",
  },
  removeElementAverage: {
    fontSize: "15px",
    marginTop: "-43px",
    cursor: "pointer",
    float: "right",
  },
  subjectRemoveIcon: {
    fontSize: "15px",
    marginTop: "-50px",
    cursor: "pointer",
    float: "right",
  },
  blankLineRemoveIcon: {
    fontSize: "15px",
    marginTop: "-38px",
    cursor: "pointer",
    float: "right",
  },
  verticalText: {
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    cursor: "n-resize",
    textAlign: "center",
    // truncate text after two lines with three dots
    overflow: "hidden",
    // textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
};

/**
 * Converts base items to interactive elements for template building
 * @Props {Obj} item  - Element of some kind to be added to the template
 * @Props {Int} index - Position of this item in the list
 * @props {func} getOptions - optienes los opciones del AsyncSelect
 * @props {array} defaulsOptions - contienes las opciones por defecto para el AsyncSelect
 * @props {func} selectClass - almacena el valor de la opcion seleccionada
 * @returns Block element.
 */
export default function ReportCardItemCreator(props) {
  const [t] = useTranslation(["reportCards"]);
  let designItem = false;
  let item = props.item;
  let index = props.position;
  const commentView = props.commentView;
  switch (item.type) {
    case "phantomElement":
      // elemente that keeps the sortable Js component even if all items have been removed.
      designItem = <Col md={12} key={index} id={index}></Col>;
      break;
    case "subject":
      designItem = (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className="mb-3 p-1"
        >
          <Row className="mx-0">
            {item.selectionMode ? (
              <Col
                xs={10}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                className="px-0"
              >
                <Form.Check
                  name="averaging"
                  type="checkbox"
                  checked={item.averaging}
                  onChange={() => {
                    props.selectClassesForAveraging(
                      item && item.value && item.value.value
                        ? item.value.value
                        : null,
                      index
                    );
                  }}
                  label={item && item.value ? item.value.label : ""}
                />
              </Col>
            ) : (
              <Col
                xs={10}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                className="px-0"
              >
                <AsyncSelect
                  loadOptions={props.getOptions}
                  defaultOptions={props.defaulsOptions}
                  onChange={(item) => props.selectClass(item, index)}
                  value={item.value ? item.value : null}
                  isDisabled={item.disabled}
                  formatOptionLabel={function (data) {
                    return (
                      <span dangerouslySetInnerHTML={{ __html: data.label }} />
                    );
                  }}
                />
              </Col>
            )}
            <Col
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              xxl={2}
              className="px-0 pt-2 text-center"
            >
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list "
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.subjectRemoveIcon}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "blankLine":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          id={index}
          key={index}
          className={commentView ? "hide" : "mb-3 p-1"}
        >
          <Row>
            <Col md={10} className="opacity-25">
              {t("reportCards:draggableElements.blankLine")}
            </Col>
            <Col md={1}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list float-end"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.blankLineRemoveIcon}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "dottedLine":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className={commentView ? "hide" : "mb-3 p-1"}
        >
          <Row>
            <Col md={10}>- - - - - - - - - - - </Col>
            <Col md={1}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list float-end"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.blankLineRemoveIcon}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "average":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className={commentView ? "hide" : "mb-3 p-1"}
        >
          <Row>
            <Col md={9}>
              <Formik initialValues={item.values}>
                {(values) => (
                  <Row>
                    <Col lg={12} style={{ maxHeight: "30px" }}>
                      <FieldInput
                        name="name"
                        type="text"
                        label={""}
                        placeholder=""
                        onBlur={() => {
                          item.values.name = values.values.name;
                        }}
                        value={values.values.name}
                        style={{
                          padding: "0px 10px 0px 10px",
                        }}
                        className={""} //set to false to remove the bottom margin
                        disabled={item.disabled}
                      />
                    </Col>
                  </Row>
                )}
              </Formik>
            </Col>

            {item.selectionMode ? (
              <Col md={1} style={customStyles.noPaddingIcons}>
                <i
                  style={{ cursor: "pointer" }}
                  className="bi bi-check-circle-fill float-end me-1 text-danger"
                  onClick={() => props.changeClassesSelectionMode(false, index)}
                ></i>
              </Col>
            ) : (
              <Col md={1} style={customStyles.noPaddingIcons}>
                <i
                  style={
                    item.disabled ? { display: "none" } : { cursor: "pointer" }
                  }
                  className="bi bi-gear-fill float-end me-1 text-danger"
                  onClick={() => props.editAveragingFormat(item.values, index)}
                  disabled={item.disabled}
                ></i>
              </Col>
            )}

            <Col md={1} style={customStyles.noPaddingIcons}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.removeElementAverage}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "absences":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className={commentView ? "hide" : "mb-3 p-1"}
        >
          <Row>
            <Col md={10}>{t("reportCards:draggableElements.assistance")}</Col>
            <Col md={1}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list float-end"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.blankLineRemoveIcon}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "groupAverage":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className={commentView ? "hide" : "mb-3 p-1"}
        >
          <Row>
            <Col md={10}>{t("reportCards:draggableElements.groupAverage")}</Col>
            <Col md={1}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list float-end"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.blankLineRemoveIcon}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "developmentArea":
      {
        item.classes = item.classes || item.subjects;
        for (let element of item.classes) {
          if (!element.value && element.values) {
            element.value = {
              value: element.values.id,
              label: element.values.name,
              developmentAreaID: element.values.developmentAreaID,
              developmentAreaName: element.values.developmentAreaName,
              title: element.values.developmentAreaName,
            };
          }
        }
        // elemente that keeps the sortable Js component even if all items have been removed.
        let heightRow = item.classes?.length * 55; // 55
        let heighTitle = item.classes?.length * 55; //  39 cuadro de texto
        item.titlem = "" + item.title;
        const charactersByElement = 15;
        const maxlengtTitle = item.classes?.length * charactersByElement;
        let title =
          item.titlem.length > maxlengtTitle
            ? item.titlem.substring(0, maxlengtTitle) + "..."
            : item.titlem;
        const listFormatItems = props.listFormatItems || [];
        designItem = (
          <Col
            md={12}
            style={customStyles.elementsItem}
            key={index}
            id={index}
            className="mb-3 p-1"
          >
            <Row style={{ maxHeight: heightRow }}>
              <Col
                md={3}
                style={{ ...customStyles.verticalText, maxHeight: heighTitle }}
                className="bi-list"
              >
                {title}
              </Col>
              <Col md={9} style={{ paddingLeft: "0" }}>
                {listFormatItems.length > 0 && (
                  <ReactSortable
                    list={item.classes} // List of items
                    setList={(newList) => {
                      const newItems = [...listFormatItems];
                      // Filter the item that is subject type
                      let newClassesList = newList.filter(
                        (item) => item.type === "subject"
                      );
                      // Get the class that does not have developmentAreaID
                      const classWithoutGroup = newClassesList.find(
                        (classList) =>
                          classList.value && !classList.value.developmentAreaID
                      );
                      // Filter classes that have the same development area
                      newClassesList = newClassesList.filter(
                        (classList) =>
                          classList.value?.developmentAreaID ===
                            item.development_area_id || !classList.value
                      );
                      newItems[index].classes = newClassesList;
                      if (classWithoutGroup) {
                        newItems.splice(index + 1, 0, classWithoutGroup);
                      }
                      props.updateFormatList(newItems);
                    }}
                    // Set the group values to only receive class type elements.
                    group={{ name: "options", pull: false, put: true }}
                  >
                    {item.classes.map((classItem, indexClass) => {
                      // classItem.value = classItem.values;
                      return (
                        <Col
                          md={12}
                          style={{
                            ...customStyles.elementsItem,
                            height: "50px",
                          }}
                          key={indexClass}
                          id={indexClass}
                          className="mb-2 p-1"
                        >
                          <Row>
                            {item.selectionMode ? (
                              <Col md={10}>
                                <Form.Check
                                  name="averaging"
                                  type="checkbox"
                                  checked={classItem.averaging}
                                  onChange={() => {
                                    props.selectClassesForAveraging(
                                      classItem &&
                                        classItem.value &&
                                        classItem.value.value
                                        ? classItem.value.value
                                        : null,
                                      index,
                                      indexClass
                                    );
                                  }}
                                  label={
                                    classItem && classItem.value
                                      ? classItem.value.label
                                      : ""
                                  }
                                />
                              </Col>
                            ) : (
                              <Col md={10}>
                                <AsyncSelect
                                  loadOptions={props.getOptions}
                                  defaultOptions={props.defaulsOptions?.[0]?.options.filter(
                                    (subject) =>
                                      subject?.developmentAreaID ==
                                      item.development_area_id // Filter the options by the development area id
                                  )}
                                  onChange={(classItem) =>
                                    props.selectClass(
                                      classItem,
                                      index,
                                      indexClass
                                    )
                                  }
                                  value={
                                    classItem?.value ||
                                    classItem?.values ||
                                    null
                                  }
                                  isDisabled={classItem.disabled}
                                  formatOptionLabel={function (data) {
                                    return (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: data.label,
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </Col>
                            )}
                            <Col md={1}>
                              <i
                                style={customStyles.pushButtonIcons}
                                className="bi bi-list float-end mt-1"
                              ></i>
                            </Col>
                          </Row>
                          {!classItem.disabled && (
                            <i
                              style={customStyles.subjectRemoveIcon}
                              className="bi bi-x-circle-fill text-danger"
                              onClick={() => {
                                const newItems = [...props.listFormatItems];
                                newItems[index].classes.splice(indexClass, 1);
                                // Remove the group if it has no elements
                                if (newItems[index].classes.length === 0)
                                  newItems.splice(index, 1);
                                // Update the list of items
                                props.updateFormatList(newItems);
                                props.refreshAvailableClassList();
                              }}
                            />
                          )}
                        </Col>
                      );
                    })}
                  </ReactSortable>
                )}
              </Col>
            </Row>
          </Col>
        );
      }
      break;
    case "main_subject":
      designItem = (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className="mb-3 p-1"
        >
          <Row className="mx-0">
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              className="px-0"
            >
              <AsyncSelect
                loadOptions={props.getSubjects}
                defaultOptions={props.defaulsSubjects}
                onChange={(item) => props.selectSubjects(item, index)}
                value={item.value ? item.value : null}
                isDisabled={item.disabled}
                formatOptionLabel={function (data) {
                  return (
                    <span dangerouslySetInnerHTML={{ __html: data.label }} />
                  );
                }}
              />
            </Col>
            <Col
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              xxl={2}
              className="px-0 pt-2 text-center"
            >
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list"
              ></i>
            </Col>
          </Row>
          <i
            style={customStyles.subjectRemoveIcon}
            className="bi bi-x-circle-fill text-danger"
            onClick={() => props.removeItemByButton(item, index)}
          ></i>
        </Col>
      );
      break;
    default:
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className="mb-3 p-1"
        >
          <i
            style={customStyles.pushButtonIcons}
            className="pushButtonIcons bi bi-list float-end"
          ></i>
          {t("reportCards:draggableElements.default")}
        </Col>
      );
  }

  return designItem;
}

ReportCardItemCreator.propTypes = {
  getOptions: PropTypes.func,
  editAveragingFormat: PropTypes.func,
};
