import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form as FormikForm, Formik } from "formik";
import * as Yup from "yup";

/** Custom Components */
import FormSelect from "../../../components/global/form/FormSelect";
import ModalSuccess from "../../../components/global/modals/ModalSuccess";

/** API Services */
import { GetOrganizationGroupCycles } from "../../../api/Organizations";
import { TransferStudents } from "../../../api/StudentsReport";

export default function ModalTransferStudent(props) {
  /** Initial values for formik form */
  const initialValues = {
    organization: "",
    school_cycle: "",
    school_level: "",
    grade: "",
    group: "",
    program: "",
    program_level: "",
  };

  /** Hooks */
  const [t] = useTranslation(["global, student"]);
  const [showModal, setShowModal] = useState(props.showModal);
  const [isLoading, setIsLoading] = useState(false);
  const [organizationsData, setOrganizationsData] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [formikForm, setFormikForm] = useState([]);
  const [isTransfering, setIsTransfering] = useState(false);
  const [error, setError] = useState("");

  /** Formik Validation With Yup */
  const validateSchema = Yup.object().shape({
    organization: Yup.object().required(
      t("student:ModalTransferStudent.organization") +
        " " +
        t("global:isRequired")
    ),
    school_cycle: Yup.object().required(
      t("student:ModalTransferStudent.schoolCycle") +
        " " +
        t("global:isRequired")
    ),
    school_level: Yup.object().required(
      t("student:ModalTransferStudent.schoolLevel") +
        " " +
        t("global:isRequired")
    ),
    grade: Yup.object().when("school_level", {
      is: (schoolLevel) => {
        const isHigherLevel = schoolLevel?.programs?.length > 0;
        return !isHigherLevel;
      },
      then: Yup.object()
        .nullable()
        .required(
          t("student:ModalTransferStudent.grade") + " " + t("global:isRequired")
        ),
      otherwise: Yup.object().nullable(),
    }),
    group: Yup.object().when(["school_level", "grade"], {
      is: (schoolLevel, grade) => {
        const isHigherLevel = schoolLevel?.programs?.length > 0;
        return !isHigherLevel && grade != "";
      },
      then: Yup.object()
        .nullable()
        .required(
          t("student:ModalTransferStudent.group") + " " + t("global:isRequired")
        ),
      otherwise: Yup.object().nullable(),
    }),
    program: Yup.object().when("school_level", {
      is: (schoolLevel) => {
        const isHigherLevel = schoolLevel?.programs?.length > 0;
        return isHigherLevel;
      },
      then: Yup.object()
        .nullable()
        .required(
          t("student:ModalTransferStudent.program") +
            " " +
            t("global:isRequired")
        ),
      otherwise: Yup.object().nullable(),
    }),
    program_level: Yup.object().when(["school_level", "program"], {
      is: (schoolLevel, program) => {
        const isHigherLevel = schoolLevel?.programs?.length > 0;
        return isHigherLevel && program != "";
      },
      then: Yup.object()
        .nullable()
        .required(
          t("student:ModalTransferStudent.programLevel") +
            " " +
            t("global:isRequired")
        ),
      otherwise: Yup.object().nullable(),
    }),
  });

  /** Initial Loading */
  useEffect(() => {
    getOrganizationsGroupCycles();
  }, []);

  /**
   * Description: Function to get cycles from the organizations of a group.
   */
  const getOrganizationsGroupCycles = () => {
    setIsLoading(true);
    const payload = {
      organization_group_id: props.organizationGroup,
    };

    GetOrganizationGroupCycles(payload)
      .then((response) => {
        if (response?.data) {
          const organizations = response.data?.map((organization) => {
            return {
              label: organization.organization_name,
              value: organization.organization_id,
              isDisabled: organization.current_organization == "1",
              school_cycles:
                organization.school_cycles?.map((cycle) => {
                  return {
                    label: cycle.school_cycle_name,
                    value: cycle.school_cycle_id,
                    school_levels:
                      cycle.school_levels?.map((level) => {
                        return {
                          label: level.school_level_name,
                          value: level.school_level_id,
                          programs:
                            level.programs?.map((program) => {
                              return {
                                label: program.program_name,
                                value: program.program_id,
                                program_levels:
                                  program.program_levels?.map((level) => {
                                    return {
                                      label: level.level,
                                      value: level.program_level_cycle_id,
                                    };
                                  }) || [],
                              };
                            }) || [],
                          grades: level.grades?.map((grade) => {
                            return {
                              label: grade.grade_name,
                              value: grade.grade_id,
                              groups:
                                grade.groups?.map((group) => {
                                  return {
                                    label: group.group_name,
                                    value: group.grade_group_grade_level_id,
                                  };
                                }) || [],
                            };
                          }),
                        };
                      }) || [],
                  };
                }) || [],
            };
          });
          setOrganizationsData(organizations);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Description: Function to transfer students to other organization from the same group
   */
  const transferStudents = () => {
    setIsTransfering(true);
    setError("");
    const payload = getFormattedPayload();
    TransferStudents(payload)
      .then((response) => {
        if (response?.description) {
          setError(response.description);
          return;
        }
        props.onHide();
      })
      .finally(() => {
        setIsTransfering(false);
      });
  };

  /**
   * Description: Function to format payload to transfer students
   * @return {payload}
   */
  const getFormattedPayload = () => {
    const { organization, program_level, group } = formikForm;
    const selectedStudents = props.selectedRowIds;
    const studentIds = selectedStudents?.map(({ original }) => original.index);

    const payload = {
      student_ids: studentIds,
      destination_organization_id: organization?.value,
    };

    // Validate if is higher or basic level
    if (program_level) {
      payload.program_level_cycle_id = program_level.value;
    } else {
      payload.grade_group_grade_level_id = group.value;
    }

    return payload;
  };

  return (
    <>
      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title>{t("student:ModalTransferStudent.title")}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          validateOnMount={true}
          onSubmit={async (values) => {
            await setFormikForm(values);
            setShowConfirmModal(true);
            setShowModal(false);
          }}
        >
          {({ values, setFieldValue, ...formik }) => {
            /**
             * Description: Function to handle changes in selects and clean up dependents
             * @param {string} field
             * @param {object} value
             * @param {array} [resetFields=[]]
             */
            const handleSelectChange = async (
              field,
              value,
              resetFields = []
            ) => {
              await setFieldValue(field, value);
              await formik.setFieldTouched(field, true);

              // Keeps previus values if they already exist in the new context
              resetFields.forEach((resetField) => {
                setFieldValue(resetField, "");
              });
            };

            return (
              <FormikForm>
                <Modal.Body>
                  {/* Organization Select */}
                  <FormSelect
                    name="organization"
                    label={t("student:ModalTransferStudent.organization")}
                    placeholder={t(
                      "student:ModalTransferStudent.organizationPlaceholder"
                    )}
                    required={true}
                    options={organizationsData}
                    onChange={(organization) =>
                      handleSelectChange("organization", organization, [
                        "school_cycle",
                        "school_level",
                        "grade",
                        "group",
                        "program",
                        "program_level",
                      ])
                    }
                    value={values.organization}
                    isDisabled={isLoading}
                  />
                  {/* School Cycle Select */}
                  <FormSelect
                    name="school_cycle"
                    label={t("student:ModalTransferStudent.schoolCycle")}
                    placeholder={t(
                      "student:ModalTransferStudent.schoolCyclePlaceholder"
                    )}
                    required={true}
                    options={
                      values.organization
                        ? values.organization.school_cycles
                        : []
                    }
                    onChange={(cycle) =>
                      handleSelectChange("school_cycle", cycle, [
                        "school_level",
                        "grade",
                        "group",
                        "program",
                        "program_level",
                      ])
                    }
                    value={values.school_cycle || ""}
                    isDisabled={!values.organization}
                  />
                  {/* School Level Select */}
                  <FormSelect
                    name="school_level"
                    label={t("student:ModalTransferStudent.schoolLevel")}
                    placeholder={t(
                      "student:ModalTransferStudent.schoolLevelPlaceholder"
                    )}
                    required={true}
                    options={
                      values.school_cycle
                        ? values.school_cycle.school_levels
                        : []
                    }
                    onChange={(level) =>
                      handleSelectChange("school_level", level, [
                        "grade",
                        "group",
                        "program",
                        "program_level",
                      ])
                    }
                    value={values.school_level}
                    isDisabled={!values.school_cycle}
                  />
                  {/** Selects according school level */}
                  {values.school_level?.programs?.length > 0 ? (
                    <>
                      {/* Program Select */}
                      <FormSelect
                        name="program"
                        label={t("student:ModalTransferStudent.program")}
                        placeholder={t(
                          "student:ModalTransferStudent.programPlaceholder"
                        )}
                        required={true}
                        options={
                          values.school_level
                            ? values.school_level.programs
                            : []
                        }
                        onChange={(program) =>
                          handleSelectChange("program", program, [
                            "program_level",
                          ])
                        }
                        value={values.program}
                        isDisabled={!values.school_level}
                      />
                      {/* Program Level Select */}
                      <FormSelect
                        name="program_level"
                        label={t("student:ModalTransferStudent.programLevel")}
                        placeholder={t(
                          "student:ModalTransferStudent.programLevelPlaceholder"
                        )}
                        required={true}
                        options={
                          values.program ? values.program.program_levels : []
                        }
                        onChange={(programLevel) =>
                          setFieldValue("program_level", programLevel)
                        }
                        value={values.program_level}
                        isDisabled={!values.program}
                      />
                    </>
                  ) : (
                    <>
                      {/* Grade Select */}
                      <FormSelect
                        name="grade"
                        label={t("student:ModalTransferStudent.grade")}
                        placeholder={t(
                          "student:ModalTransferStudent.gradePlaceholder"
                        )}
                        required={true}
                        options={
                          values.school_level ? values.school_level.grades : []
                        }
                        onChange={(grade) =>
                          handleSelectChange("grade", grade, ["group"])
                        }
                        value={values.grade}
                        isDisabled={!values.school_level}
                      />
                      {/* Group Select */}
                      <FormSelect
                        name="group"
                        label={t("student:ModalTransferStudent.group")}
                        placeholder={t(
                          "student:ModalTransferStudent.groupPlaceholder"
                        )}
                        required={true}
                        options={values.grade ? values.grade.groups : []}
                        onChange={(group) => setFieldValue("group", group)}
                        value={values.group}
                        isDisabled={!values.grade}
                      />
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <div>
                    <Button
                      variant="outline-secondary"
                      onClick={props.onHide}
                      disabled={isLoading}
                    >
                      {t("global:buttons.cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isLoading || !formik.isValid}
                    >
                      {t("student:ModalTransferStudent.transfer")}
                    </Button>
                  </div>
                </Modal.Footer>
              </FormikForm>
            );
          }}
        </Formik>
      </Modal>
      {showConfirmModal && (
        <ModalSuccess
          showModalSuccess={showConfirmModal}
          title={t("student:ModalTransferStudent.titleConfirm")}
          message={t("student:ModalTransferStudent.descriptionConfirm")}
          fnCancelButton={props.onHide}
          txtBtnCancel={t("global:buttons.close")}
          fnAcceptButton={transferStudents}
          txtBtnAccept={t("student:ModalTransferStudent.transfer")}
          loading={isTransfering}
          error={error}
        />
      )}
    </>
  );
}

ModalTransferStudent.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  organizationGroup: PropTypes.string,
  selectedRowIds: PropTypes.array,
};
