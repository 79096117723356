import axios from "axios";
import { validateErrorResponse, ErrorResponse } from "./AxiosExtension";

let axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  //method: "get",
};
const prefix = process.env.REACT_APP_PREFIX;

/**
 * Purpose: Get the students list
 * @param {Id} schoolCycleId
 * @returns
 */
export const GetStudentsList = async (schoolCycleId) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/report`;
  axiosOptions.params = {
    school_cycle_id: schoolCycleId,
    visible: "admin",
  };

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the students list by parent
 * @param {Id} parentId
 * @returns
 */
export const GetStudentsListParent = async (parentId) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/`;
  axiosOptions.params = {
    parent_id: parentId,
    organization_id: localStorage.getItem(`cmOrganizationID${prefix}`),
  };

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the school cycle list
 * @returns
 */
export const GetSchoolCycle = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/school_cycles`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Download template
 * @returns
 */
export const DownloadTemplate = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/assets/csv/estudiantesV2.csv`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the school levels
 * @returns
 */
export const getSchoolLevels = async (schoolCycleId) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/organization_school_levels`;
  axiosOptions.params = { organization_school_level_cycle_id: schoolCycleId };

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Get the grades levels
 * @returns
 */
export const getGradeLevels = async (schoolLeveId) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/grade_levels`;
  axiosOptions.params = { organization_school_level_cycle_id: schoolLeveId };

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * get groups id
 * @param {id} gradeId
 * @returns
 */
export const getGradeGroups = async (values) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/grade_group_grade_levels`;
  axiosOptions.params = {
    grade_level_id: values.gradeId,
    organization_school_level_cycle_id: values.cycle_id,
    limit: 0,
  };

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose:Send Invitation to Parents or Guardians
 * @returns
 */
export const sendInvitationToParentsOrGuardians = async (arrayStudents) => {
  axiosOptions.method = "post";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/share`;
  axiosOptions.params = {
    student_ids: arrayStudents,
  };

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Create Student
 * @param {File} file
 * @param {Id} schoolCycleId
 */
export const createStudent = async (student) => {
  let data = Object.assign({}, student);

  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Import students csv
 * @param {File} file
 * @param {Id} schoolCycleId
 */
export const importStudentsCSV = async (file, schoolCycleId) => {
  let data = new FormData();
  data.append("file", file);
  data.append("school_cycle_id", schoolCycleId);

  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
    "Content-Type": "multipart/form-data",
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/import`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose:Send report cards by mail
 * @param {object} data
 * @returns
 */
export const sendReportCardsMail = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/report_cards`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Obtains the periods of a school level or a program
 * @param {object} data
 * @returns
 */
export const getSchoolLevelPeriods = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/grading_periods`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose:Download report cards by mail
 * @param {object} data
 * @returns
 */
export const downloadReportCardsMail = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/report_cards/download_zip`;
  axiosOptions.responseType = "blob";
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Function to change the level of students
 * @param {object} data
 * @return {JSON}
 */
export const ChangeStudentLevel = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/change_level`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Function to delete a student
 * @param {object} data
 * @return {JSON}
 */
export const DeleteStudent = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Function to get product concepts of the school cycle.
 * @param {object} data
 * @return {JSON}
 */
export const GetProductConcepts = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/product_concepts`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Function to add products at students
 * @param {object} data
 * @return {JSON}
 */
export const CreateProductsMultiple = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/students/products_multiple`;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Description: Function to disable multiple students
 * @param {object} data
 * @return {JSON}
 */
export const DisableStudents = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/disable`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

export const GetStudentsReduced = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/fast`;
  //axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/reduced`; servicio anterior lento
  axiosOptions.params = data;
  // search ? (axiosOptions.params.search = search) : null;
  // limit ? (axiosOptions.params.limit = limit) : null;
  // offset ? (axiosOptions.params.offset = offset) : null;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

export const getRegimenFiscal = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/cfdi_catalogs/regimen_fiscal`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Get the data from the students
 * @param {Array} values params
 * @returns API response
 */
export const GetStudentsBySchoolLevelId = async (values) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/students/gruop`;
  axiosOptions.params = values;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 *Purpose: Download template medical record
 */
export const DownloadTemplateMedicalRecord = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/assets/csv/medical_info.csv`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * @param {File} file
 */
export const importMedicalRecordCSV = async (file) => {
  let data = new FormData();
  data.append("students_medical_info_csv", file);

  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
    "Content-Type": "multipart/form-data",
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/import_medical_info`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 *Purpose: Download medical record report
 */
export const DownloadReportMedicalRecord = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/download_medical_info`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 *Purpose: Download bulk export credentials
 *@param {object} data
 */
export const DownloadBulkExportCredentials = async (data) => {
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/students/bulk_credential_export`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 *Purpose: Function to start diploma generation
 *@param {object} data
 */
export const GenerateDiplomas = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/diplomas_details`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 *Purpose: Function to create additional fields
 * @param {*} data
 * @returns
 */
export const CreateAdditionalFields = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/organizations/additional_fields`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 *Purpose: Function to update additional fields
 * @param {*} data
 * @returns
 */
export const UpdateAdditionalFields = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/organizations/additional_fields`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 *Purpose: Function to create additional fields
 * @returns json
 */
export const getAdditionalFieldsSections = async () => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/organizations/additional_fields_sections`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 *Porpuse: Function to get additional fields
 * @param {object} data
 * @returns json
 */
export const getOrganizationAdditionalFields = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/organizations/additional_fields`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Function to transfer student to other organization
 * @param {object} [data={}]
 * @return {JSON object} Response from API
 */
export const TransferStudents = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/students/transfer`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};
