import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import PropTypes from "prop-types";

//Custom styles
const customStyles = {
  solidBackground: {
    background: "#FFFFFF",
    padding: "30px 20px",
    minHeight: "100%",
    border: "solid 1px #cccccc",
    borderRadius: "5px",
  },
  draggableElements: {
    border: "solid 1px #cccccc",
    borderRadius: "8px",
    cursor: "move",
  },
  noDraggableElements: {
    border: "solid 1px #cccccc",
    borderRadius: "8px",
    opacity: ".25",
  },
};
export default function DraggableElements(props) {
  const [t] = useTranslation(["reportCards"]);
  const [draggableElements, setDraggableElements] = useState([]);
  const [reloadDraggableList, setReloadDraggableList] = useState(false);
  // Effect for loading initial translations
  useEffect(() => {
    if (typeof t == "function") {
      setDraggableElements([
        {
          id: "8",
          name: t("reportCards:draggableElements.main_subject"),
          type: "main_subject",
          draggable: true,
        },
        {
          id: "1",
          name: t("reportCards:draggableElements.subject"),
          type: "subject",
          disabled: false,
          averaging: true,
          selectionMode: false,
          draggable: true,
        },
        {
          id: "2",
          name: t("reportCards:draggableElements.blankLine"),
          type: "blankLine",
          draggable: true,
        },
        {
          id: "3",
          name: t("reportCards:draggableElements.dottedLine"),
          type: "dottedLine",
          draggable: true,
        },
        {
          id: "4",
          name: t("reportCards:draggableElements.average"),
          type: "average",
          values: {
            name: "",
            averageFormat: {
              gradePeriodFormat: {
                label: t(
                  "reportCards:settingAveragingFormatModal.integerNumbers"
                ),
                value: "int",
              },
              averageNumberFormat: {
                label: t(
                  "reportCards:settingAveragingFormatModal.decimalNumbers"
                ),
                value: "decimal",
              },
              decimalsToUse: {
                label: 1,
                value: 1,
              },
              averageRounding: "",
              doNotRound: true,
            },
            averageClasses: [],
          },
          selectionMode: false,
          disabled: false,
          draggable: true,
        },
        {
          id: "6",
          name: t("reportCards:draggableElements.assistance"),
          type: "absences",
          draggable: true,
        },
        {
          id: "7",
          name: t("reportCards:draggableElements.groupAverage"),
          type: "groupAverage",
          values: { name: "" },
          draggable: true,
        },
      ]);
    }
  }, [t]);

  /**
   * Effect for detecting the use and disuse of a unique field
   */
  useEffect(() => {
    setReloadDraggableList(true);
    let uniqueItems = props.uniqueItems;
    for (let property in uniqueItems) {
      let position = draggableElements.findIndex(
        (item) => item.type == property
      );
      if (position != -1) {
        draggableElements[position].draggable = uniqueItems[property];
        setDraggableElements(draggableElements);
      }
    }
    setTimeout(() => {
      setReloadDraggableList(false);
    }, 0);
  }, [props.uniqueItems]);

  return (
    <Col md={12} style={customStyles.solidBackground}>
      <h5 className="mb-4 fw-bold">{t("reportCards:page.elements")}</h5>
      {reloadDraggableList ? null : (
        <ReactSortable
          filter=".disabled"
          list={draggableElements}
          setList={setDraggableElements}
          group={
            { name: "options", pull: "clone", put: false } // Do not allow items to be put into this list
          }
          sort={false}
          animation={200}
          delayOnTouchStart={true}
        >
          {draggableElements.map((item) => (
            <Col
              md={12}
              key={item.id}
              style={
                item.draggable
                  ? customStyles.draggableElements
                  : customStyles.noDraggableElements
              }
              className={item.draggable ? "mb-3 p-1" : "mb-3 p-1 disabled"}
            >
              <i className="bi bi-arrows-move me-2"></i>
              {item.name}
            </Col>
          ))}
        </ReactSortable>
      )}
    </Col>
  );
}

DraggableElements.propTypes = {
  uniqueItems: PropTypes.object,
};
