import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  getUrlParamValue,
  imgOrganization,
} from "../components/global/GlobalTools";
import TextLoading from "../components/global/TextLoading";

const customStyles = {
  container: {
    display: "grid",
    height: "80vh",
    width: "80vw",
    justifyItems: "center",
    alignContent: "center",
  },
  imgLogo: {
    height: "120px",
    width: "120px",
    borderRadius: "4px",
    verticaAlign: "top",
  },
  buttonsContainer: { display: "flex", gap: "10px" },
};

const ConfirmTransferView = () => {
  const history = useHistory();
  const urlImg = imgOrganization();
  const [t] = useTranslation(["global", "student"]);
  const [isLoadingLogo, setIsLoadingLogo] = useState(true);
  const [organizationLogo, setOrganizationLogo] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [requestStatus, setRequestStatus] = useState("");
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [userType, setUserType] = useState("");
  const [userId, setUserId] = useState("");

  const transferStatus = {
    approved: {
      icon: "fal fa-5x fa-check-circle",
      style: {
        color: "#07C25F",
      },
      redirect: () => history.push("/dashboard"),
      redirectText: t("global:confirmTransferView.goToDashboard"),
    },
    rejected: {
      icon: "fal fa-5x fa-times-circle",
      style: {
        color: "#EA2C54",
      },
      redirect: () => history.push("/dashboard"),
      redirectText: t("global:confirmTransferView.goToDashboard"),
    },
  };

  /** Initial Loading */
  useEffect(() => {
    getInitialProps();
  }, []);

  const getInitialProps = () => {
    const userType = getUrlParamValue("userType", true) || "student";
    const userId = getUrlParamValue("userId", true);
    setUserType(userType);
    setUserId(userId);
  };

  /** Load organization logo */
  useEffect(() => {
    if (urlImg) {
      setOrganizationLogo(urlImg?._currentValue?.imgOrganizationUrl);
      setIsLoadingLogo(false);
    }
  }, [urlImg]);

  const confirmTransfer = (status) => {
    setRequestStatus(status);
    setIsSubmiting(true);

    //TODO: Connect service to accept or reject transfer
    setTimeout(() => {
      setIsSubmiting(false);
      setShowForm(false);
    }, 1000);
  };

  return (
    <Container fluid style={customStyles.container}>
      {/** Organization Logo */}
      <Row className="mb-4">
        <Col md={12}>
          {!isLoadingLogo && (
            <Image
              className="thumbnail-image"
              src={organizationLogo}
              style={customStyles.imgLogo}
            />
          )}
        </Col>
      </Row>
      {showForm && (
        <>
          {/** Title */}
          <Row className="mb-3">
            <Col md={12}>
              <h3 className="fw-bold">
                {t(`${userType}:confirmTransferView.title`)}
              </h3>
            </Col>
          </Row>
          {/** Description */}
          <Row className="mb-3">
            <Col md={12}>
              {t(`${userType}:confirmTransferView.description`)}
            </Col>
          </Row>
          {/** Buttons */}
          <Row>
            <Col md={12} style={customStyles.buttonsContainer}>
              <Button
                variant="secondary"
                onClick={() => confirmTransfer("rejected")}
                disabled={isSubmiting || !userId}
              >
                {isSubmiting && requestStatus == "rejected" ? (
                  <TextLoading
                    text={t("global:confirmTransferView.rejecting")}
                    variant="light"
                  />
                ) : (
                  t("global:confirmTransferView.rejectTransfer")
                )}
              </Button>
              <Button
                variant="primary"
                onClick={() => confirmTransfer("approved")}
                disabled={isSubmiting || !userId}
              >
                {isSubmiting && requestStatus == "approved" ? (
                  <TextLoading
                    text={t("global:confirmTransferView.transferring")}
                    variant="light"
                  />
                ) : (
                  t("global:confirmTransferView.confirmTransfer")
                )}
              </Button>
            </Col>
          </Row>
        </>
      )}
      {!showForm && requestStatus && (
        <>
          {/** Status Message */}
          <Row className="mb-3">
            <Col md={12}>
              <h3 className="fw-bold">
                {t("global:confirmTransferView.transferRequest") +
                  " " +
                  t(`global:confirmTransferView.${requestStatus}`)}
              </h3>
            </Col>
          </Row>
          {/** Status Icon */}
          <Row className="mb-5">
            <Col md={12}>
              <i
                className={transferStatus[requestStatus]?.icon}
                style={transferStatus[requestStatus]?.style}
              />
            </Col>
          </Row>
          {/** Redirect Button */}
          <Row className="mb-3">
            <Col md={12}>
              <Button
                variant="primary"
                onClick={transferStatus[requestStatus]?.redirect}
              >
                {transferStatus[requestStatus]?.redirectText}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ConfirmTransferView;
